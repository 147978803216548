import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import throttle from 'lodash.throttle';

import { search } from 'store/slices/allGames';

import SearchInput from 'components/UI/SearchInput/SearchInput';

import styles from './Search.module.scss';

const Search = () => {
  const dispatch = useDispatch();

  const handleAutocomplete = useCallback(
    throttle((event) => {
      event.stopPropagation();
      event.preventDefault();
      dispatch(search(event.target.value.toLowerCase().trim()));
    }, 500),
    [],
  );

  useEffect(() => {
    return () => dispatch(search(''));
  }, []);

  return (
    <SearchInput
      handleChange={handleAutocomplete}
      customStyle={styles.wrapper}
      placeholder="Search by name or ID"
    />
  );
};

export default Search;
