import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import classNames from 'classnames';

import { useGetRoadmapQuery } from 'store/api/gameService/recipe.api';
import { show } from 'store/slices/alert';
import { roadmapSelector, toggleVisible } from 'store/slices/roadmap';

import { getMonthName } from 'utils/date';

import IconButton from '@mui/material/IconButton';

import Loader from 'components/Loader/Loader';
import Button from 'components/UI/Button/Button';
import Calendar from 'components/UI/Calendar/Event/Calendar';
import Item from 'components/pages/Roadmap/Item/Item';

import { pdfPath, xlsxPath } from 'components/config/roadmap';

import { ReactComponent as ArrowCircleIcon } from 'images/Icons/ArrowCircle.svg';
import { ReactComponent as CalendarIcon } from 'images/Icons/Calendar.svg';
import { ReactComponent as DownloadIcon } from 'images/Icons/Download.svg';

import styles from './Roadmap.module.scss';

const Roadmap = () => {
  const dispatch = useDispatch();
  const [currentDate, setCurrentDate] = useState(new Date());
  const { provider } = useParams();
  const isVisible = useSelector(roadmapSelector.getVisible);

  const prevMonth = useMemo(
    () => new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1),
    [currentDate],
  );
  const nextMonth = useMemo(
    () =>
      currentDate.getMonth() === 11
        ? new Date(currentDate.getFullYear() + 1, 0, 1)
        : new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1),
    [currentDate],
  );

  const {
    data: currentData = [],
    isFetching: isFetchingCurrent,
    error: errorCurrent,
  } = useGetRoadmapQuery({
    month: currentDate.getMonth() + 1,
    year: currentDate.getFullYear(),
    provider,
  });
  const {
    data: nextData = [],
    isFetching: isFetchingNext,
    error: errorNext,
  } = useGetRoadmapQuery({
    month: nextMonth.getMonth() + 1,
    year: nextMonth.getFullYear(),
    provider,
  });

  const handleClick = useCallback(
    () => dispatch(toggleVisible(!isVisible)),
    [isVisible],
  );
  const handlePrevMonth = useCallback(
    () => setCurrentDate(prevMonth),
    [prevMonth],
  );
  const handleNextMonth = useCallback(
    () => setCurrentDate(nextMonth),
    [nextMonth],
  );
  const handleSetMonth = useCallback((date) => setCurrentDate(date), []);

  useEffect(() => {
    if (errorCurrent?.data?.errorMessage) {
      dispatch(show({ type: 'error', text: errorCurrent.data.errorMessage }));
    }
    if (errorNext?.data?.errorMessage) {
      dispatch(show({ type: 'error', text: errorNext.data.errorMessage }));
    }
  }, [errorCurrent, errorNext]);

  return (
    <>
      {isVisible && (
        <div className={styles.calendar}>
          <Calendar
            data={currentData.concat(nextData)}
            currentDate={currentDate.toString()}
            handleSetMonth={handleSetMonth}
          />
          <div className={styles.link}>
            <span onClick={handleClick}>Hide</span>
          </div>
        </div>
      )}
      <div className={classNames(styles.content, styles.actions)}>
        <div className={styles.roadmapBtn}>
          <div>Roadmap</div>
          <Button
            variant="icon"
            target="_blank"
            rel="noreferrer"
            download
            component={Link}
            to={pdfPath[provider]}
            startIcon={<DownloadIcon width={24} height={24} />}
          >
            PDF
          </Button>
          <Button
            variant="icon"
            target="_blank"
            rel="noreferrer"
            download
            component={Link}
            to={xlsxPath[provider]}
            startIcon={<DownloadIcon width={24} height={24} />}
          >
            XLSX
          </Button>
        </div>
        <IconButton onClick={handleClick}>
          <CalendarIcon
            className={classNames(styles.calendarIcon, {
              [styles.active]: isVisible,
            })}
          />
        </IconButton>
      </div>
      <div className={classNames(styles.content, styles.itemsWrapper)}>
        <div>
          <div className={classNames(styles.activeMonths, styles.leftArrow)}>
            <ArrowCircleIcon onClick={handlePrevMonth} />
            {getMonthName(currentDate.getMonth())} {currentDate.getFullYear()}
          </div>
          <div className={styles.scroll}>
            {isFetchingCurrent && <Loader />}
            {!isFetchingCurrent &&
              currentData &&
              currentData.map((item, index) => <Item key={index} {...item} />)}
          </div>
        </div>
        <div>
          <div className={styles.activeMonths}>
            {getMonthName(nextMonth.getMonth())} {nextMonth.getFullYear()}
            <ArrowCircleIcon onClick={handleNextMonth} />
          </div>
          <div className={styles.scroll}>
            {isFetchingNext && <Loader />}
            {!isFetchingNext &&
              nextData &&
              nextData.map((item, index) => <Item key={index} {...item} />)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Roadmap;
