import React from 'react';
import { useParams } from 'react-router-dom';

import classNames from 'classnames';

import { useGetPostQuery } from 'store/api/gameService/recipe.api';

import { findPatchPages } from 'utils/pages';

import ArrowCircleLink from 'components/ArrowCircleLink/ArrowCircleLink';
import Loader from 'components/Loader/Loader';
import Type from 'components/pages/Game/Type/Type';
import LatestNews from 'components/pages/NewsAndUpdates/LatestNews/LatestNews';
import NotFound from 'components/pages/NotFound/NotFound';

import { News as NewsData, categories } from 'components/config/news';
import { PAGE_PATH } from 'components/config/pages';
import { Updates } from 'components/config/updates';

import styles from './News.module.scss';
import stylesGame from 'components/pages/Game/Game.module.scss';

const News = () => {
  const { item, category } = useParams();
  const data = category === 'updates' ? Updates : NewsData;
  const { isLoading, data: postData } = useGetPostQuery(item);

  if (isLoading) {
    return <Loader />;
  }

  const itemNews = data?.find((el) => el.title === item) || postData;

  if (!itemNews) {
    return <NotFound />;
  }

  return (
    <>
      <div
        className={stylesGame.header}
        style={{
          backgroundImage: `url(${itemNews.background})`,
          backgroundPosition: 'right',
        }}
      >
        <div className={stylesGame.content}>
          <div className={stylesGame.headerGradient}></div>
          <div className={stylesGame.headerContent}>
            <div className={stylesGame.back}>
              <ArrowCircleLink
                style={stylesGame.backLink}
                path={findPatchPages(PAGE_PATH.NEWS)}
                title="Go to all news"
                direction="left"
              />
            </div>
            <div className={classNames(stylesGame.mainInfo, styles.mainInfo)}>
              <Type type={categories[category].title} />
              <div className={stylesGame.title}>
                <span>{itemNews.title}</span>
              </div>
              <div>{itemNews.date}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        {itemNews.id && (
          <h3 dangerouslySetInnerHTML={{ __html: itemNews.shortDescription }} />
        )}
        <div
          dangerouslySetInnerHTML={{ __html: itemNews.description }}
          className={classNames({ [styles.contentShrink]: itemNews.id })}
        ></div>
      </div>
      <LatestNews />
    </>
  );
};

export default News;
