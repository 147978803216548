import { findPatchPages } from 'utils/pages';

import { PAGE_PATH } from 'components/config/pages';

import { ReactComponent as Account } from 'images/Icons/Account.svg';
import { ReactComponent as Bingo } from 'images/Icons/Bingo.svg';
import { ReactComponent as BrandAssets } from 'images/Icons/BrandAssets.svg';
import { ReactComponent as CalendarIcon } from 'images/Icons/Calendar.svg';
import { ReactComponent as Documentation } from 'images/Icons/Documentation.svg';
import { ReactComponent as Game } from 'images/Icons/Game.svg';
import { ReactComponent as Home } from 'images/Icons/Home.svg';
import { ReactComponent as News } from 'images/Icons/News.svg';
import { ReactComponent as Promotions } from 'images/Icons/Promotions.svg';
import { ReactComponent as Support } from 'images/Icons/Support.svg';
import { ReactComponent as TournamentsB2B } from 'images/Icons/TournamentsB2B.svg';
import { ReactComponent as Tutorials } from 'images/Icons/Tutorials.svg';

export const data = [
  {
    title: 'Home',
    path: findPatchPages(PAGE_PATH.HOME),
    icon: (props) => <Home {...props} />,
  },
  {
    title: 'Roadmap',
    icon: (props) => <CalendarIcon {...props} width={24} height={24} />,
    subNav: [
      {
        title: 'Evoplay',
        path: findPatchPages(PAGE_PATH.ROADMAP).replace(':provider', 'evoplay'),
      },
      {
        title: 'Slotopia',
        path: findPatchPages(PAGE_PATH.ROADMAP).replace(
          ':provider',
          'slotopia',
        ),
      },
    ],
  },
  {
    title: 'All games',
    icon: (props) => <Game {...props} width={24} height={24} />,
    subNav: [
      {
        title: 'Evoplay',
        path: findPatchPages(PAGE_PATH.ALL_GAMES).replace(
          ':provider',
          'evoplay',
        ),
      },
      {
        title: 'Slotopia',
        path: findPatchPages(PAGE_PATH.ALL_GAMES).replace(
          ':provider',
          'slotopia',
        ),
      },
    ],
  },
  {
    title: 'Bingo',
    path: findPatchPages(PAGE_PATH.BINGO),
    icon: (props) => <Bingo {...props} />,
  },
  {
    title: 'Promotions',
    icon: (props) => <Promotions {...props} />,
    subNav: [
      {
        title: 'Evoplay',
        path: findPatchPages(PAGE_PATH.PROMOTIONS).replace(
          ':provider',
          'evoplay',
        ),
      },
      {
        title: 'Slotopia',
        path: findPatchPages(PAGE_PATH.PROMOTIONS).replace(
          ':provider',
          'slotopia',
        ),
      },
    ],
  },
  {
    title: 'B2B Tournaments',
    path: findPatchPages(PAGE_PATH.TOURNAMENTS),
    icon: (props) => <TournamentsB2B {...props} />,
  },
  {
    title: 'Tutorials',
    icon: (props) => <Tutorials {...props} />,
    subNav: [
      {
        title: 'Bonus House',
        path: findPatchPages(PAGE_PATH.TUTORIALS).replace(
          ':system',
          'bonusHouse',
        ),
      },
      {
        title: 'Back Office',
        path: findPatchPages(PAGE_PATH.TUTORIALS).replace(
          ':system',
          'backOffice',
        ),
      },
    ],
  },
  {
    title: 'Documentation',
    path: findPatchPages(PAGE_PATH.DOCUMENTATION),
    icon: (props) => <Documentation {...props} />,
  },
  {
    title: 'News and Updates',
    path: findPatchPages(PAGE_PATH.NEWS),
    icon: (props) => <News {...props} />,
  },
  {
    title: 'Brand Assets',
    icon: (props) => <BrandAssets {...props} />,
    subNav: [
      {
        title: 'Evoplay',
        path: findPatchPages(PAGE_PATH.BRAND_ASSETS).replace(
          ':provider',
          'evoplay',
        ),
      },
      {
        title: 'Slotopia',
        path: findPatchPages(PAGE_PATH.BRAND_ASSETS).replace(
          ':provider',
          'slotopia',
        ),
      },
    ],
  },
  {
    title: 'Support',
    path: findPatchPages(PAGE_PATH.SUPPORT),
    icon: (props) => <Support {...props} />,
  },
  {
    title: 'Personal Account',
    path: findPatchPages(PAGE_PATH.ACCOUNT),
    icon: (props) => <Account {...props} />,
  },
];
