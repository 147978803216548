// TODO: calculate 1441 (width content) + 40 (padding 20px) + 255 (sidebar)
// TODO: if change need change variables.scss $wideMode
const WIDE = 1736;

export const VIEW_MODE = {
  SIMPLE: 'simple',
  WIDE: 'wide',
};

export const getViewMode = (innerWidth = window.innerWidth) =>
  innerWidth >= WIDE ? VIEW_MODE.WIDE : VIEW_MODE.SIMPLE;
