import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { formatViewDate } from 'utils/date';
import { findPatchPages } from 'utils/pages';

import Type from 'components/GameItem/Type/Type';

import { PAGE_PATH } from 'components/config/pages';

import ComingSoonImg from 'images/Roadmap/ComingSoon.jpg';

import styles from 'components/pages/Home/ComingSoon/Item/Item.module.scss';

const propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.string,
    name: PropTypes.string.isRequired,
    gameCode: PropTypes.string.isRequired,
    releaseDate: PropTypes.string,
    marker: PropTypes.string,
  }).isRequired,
};

const Item = ({ item: { icon, name, releaseDate, gameCode, marker } }) => (
  <Link
    className={styles.wrapper}
    to={findPatchPages(PAGE_PATH.GAME).replace(':gameCode', gameCode)}
  >
    {marker && <Type type={marker} />}
    <img src={icon || ComingSoonImg} alt={name} />
    <div className={styles.title}>{name}</div>
    {releaseDate && (
      <div className={styles.dateRelease}>
        {formatViewDate(new Date(releaseDate))}
      </div>
    )}
  </Link>
);

Item.propTypes = propTypes;
export default Item;
