import React from 'react';
import { useSelector } from 'react-redux';

import useAccessView from 'hooks/tournaments/accessView';

import { sidebarSelector } from 'store/slices/sidebar';

import Copyright from 'components/Copyright/Copyright';
// import Search from 'components/Sidebar/Search/Search';
import Logo from 'components/Logo/Logo';
import ContentLinks from 'components/Sidebar/ContentLinks/ContentLinks';
import Item from 'components/Sidebar/Item/Item';

import { data } from 'components/config/sidebar';

import styles from 'components/Sidebar/Sidebar.module.scss';

const Sidebar = () => {
  const isVisibleSidebar = useSelector(sidebarSelector.getVisible);
  const hasAccessViewTournaments = useAccessView();

  return (
    <div className={styles.wrapper}>
      <div>
        <Logo />
        {/*<Search />*/}
        <div>
          {data.map((item) => {
            if (item.title === 'B2B Tournaments' && !hasAccessViewTournaments) {
              return null;
            }
            return <Item {...item} key={item.title} />;
          })}
        </div>
      </div>
      {isVisibleSidebar && (
        <div className={styles.footer}>
          <ContentLinks />
          <Copyright />
        </div>
      )}
    </div>
  );
};

export default Sidebar;
