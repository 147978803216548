import React from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { stripHtml } from 'utils/formatters';
import { findPatchPages } from 'utils/pages';

import { PAGE_PATH } from 'components/config/pages';

import styles from 'components/pages/NewsAndUpdates/NewsAndUpdates.module.scss';

const propTypes = {
  id: PropTypes.number,
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};

const List = ({ id, title, img, shortDescription, date, category }) => (
  <Link
    className={classNames(styles.item, styles.link)}
    to={findPatchPages(PAGE_PATH.NEWS_ITEM)
      .replace(':category', category)
      .replace(':item', id ?? title)}
  >
    <img src={img} alt={title} />
    <div className={styles.info}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{stripHtml(shortDescription)}</div>
      <div>{date}</div>
    </div>
  </Link>
);

List.propTypes = propTypes;
export default List;
