import EvoplayScoresBg from 'images/NewsAndUpdates/News/1500-1.jpg';
import EvoplaySweeps7Bg from 'images/NewsAndUpdates/News/1500-2.jpg';
import EvoplaySecuresBg from 'images/NewsAndUpdates/News/1500x420_1.jpg';
import MegaGreatestBg from 'images/NewsAndUpdates/News/1500x420_2.jpg';
import EvoplayScoopsBg from 'images/NewsAndUpdates/News/1500х420_3_2.jpg';
import GamePromotional2Bg from 'images/NewsAndUpdates/News/1500х420_4.jpg';
import GamePromotionalBg from 'images/NewsAndUpdates/News/1500х420_6.jpg';
import StarGuardiansBg from 'images/NewsAndUpdates/News/1500х420_7.jpg';
import BestOnlineCasinoImg from 'images/NewsAndUpdates/News/BestOnlineCasino.jpg';
import BestOnlineCasinoBg from 'images/NewsAndUpdates/News/BestOnlineCasinoBg.jpg';
import DoubleTriumphImg from 'images/NewsAndUpdates/News/DoubleTriumph.jpg';
import DoubleTriumphBg from 'images/NewsAndUpdates/News/DoubleTriumphBg.jpg';
import EvoplayGGAImg from 'images/NewsAndUpdates/News/EvoplayGGA.jpg';
import EvoplayGGABg from 'images/NewsAndUpdates/News/EvoplayGGABg.jpg';
import FourShortlistsImg from 'images/NewsAndUpdates/News/FourShortlists.jpg';
import FourShortlistsBg from 'images/NewsAndUpdates/News/FourShortlistsBg.jpg';
import InnovationImg from 'images/NewsAndUpdates/News/Innovation.jpg';
import InnovationBg from 'images/NewsAndUpdates/News/InnovationBg.jpg';
import MeetEvoplayImg from 'images/NewsAndUpdates/News/MeetEvoplay.jpg';
import MeetEvoplayBg from 'images/NewsAndUpdates/News/MeetEvoplayBg.jpg';
import SIGMAAmericas from 'images/NewsAndUpdates/News/SIGMAAmericas.jpg';
import SIGMAAmericasBG from 'images/NewsAndUpdates/News/SIGMAAmericasBG.jpg';
import SlotsWiseImg from 'images/NewsAndUpdates/News/SlotsWise.jpg';
import SlotsWiseBg from 'images/NewsAndUpdates/News/SlotsWiseBg.jpg';
import TwoWinsBg from 'images/NewsAndUpdates/News/cup.jpg';
import Shortlists2Img from 'images/NewsAndUpdates/News/shortlists2.jpg';
import Shortlists2Bg from 'images/NewsAndUpdates/News/shortlists2bg.jpg';

export const categories = {
  news: {
    title: 'News',
    postType: 'NEWS',
  },
  updates: {
    title: 'Tech and Product Updates',
    postType: 'UPDATE',
  },
};

export const News = [
  {
    title:
      'Get Ready for SIGMA Americas: Join Us in Brazil for an Unforgettable Experience!',
    img: SIGMAAmericas,
    background: SIGMAAmericasBG,
    shortDescription:
      'We&apos;re thrilled to announce our participation in SIGMA Americas',
    description:
      '<p><h3>We&apos;re thrilled to announce our participation in SIGMA Americas, set to take place in the vibrant heart of Brazil.</h3></p>' +
      '<p>SIGMA Americas promises to be an unparalleled gathering of industry leaders, innovators, and enthusiasts, providing the perfect platform to explore new opportunities and forge meaningful connections.</p>' +
      '<p>Join us as we dive into discussions, exchange ideas, and unveil exciting possibilities for the future. We invite you to connect with us at SIGMA to explore how we can collaborate and drive success together. Let&apos;s seize the moment, embrace innovation, and shape the future of our industry.</p>' +
      '<p><strong>Book a meeting with our Key Account Managers today to discuss exciting opportunities ahead! We can&apos;t wait to see you there!</strong></p>',
    date: '08.04.2024',
  },
  {
    title: 'Evoplay shines bright with silver at GGA!',
    img: EvoplayGGAImg,
    background: EvoplayGGABg,
    shortDescription:
      'Evoplay has earned a new wave of recognition at Global Gaming Awards (GGA).',
    description:
      '<p><h3>Evoplay has earned a new wave of recognition at <a href="https://www.globalgamingawards.com/" target="_blank" rel="noreferrer">Global Gaming Awards (GGA)</a>. Our game, <a href="https://client-area.evoplay.games/game/the_greatest_catch_bonus_buy" target="_blank" rel="noreferrer">The Greatest Catch Bonus Buy</a>, has been awarded silver in the Product Launch of the Year category. Receiving such an award is a testament to the talent and creativity of the Evoplay team. We’re incredibly proud of the hard work and dedication that brought this title to life and further success.</h3></p>' +
      '<p>As we celebrate this achievement, our dedication to raising the bar for excellence in the industry remains unwavering. We keep working to deliver top-notch entertainment products to iGaming. Stay tuned for thrilling new game releases from Evoplay!</p>',
    date: '12.02.2024',
  },
  {
    title: 'Evoplay clinches Innovator 2023 at SlotsWise Gaming Awards!',
    img: SlotsWiseImg,
    background: SlotsWiseBg,
    shortDescription:
      'Evoplay proudly announces its recognition as Innovator 2023 at the SlotsWise Gaming Awards!',
    description:
      '<p>Evoplay proudly announces its recognition as <strong>Innovator 2023</strong> at the <a href="https://www.slotswise.com/awards/" target="_blank" rel="noreferrer">SlotsWise Gaming Awards</a>! This win is a testament to our relentless commitment to pushing the boundaries of innovation in the iGaming industry. At Evoplay, we’ve always believed in challenging the status quo and embracing a groundbreaking approach in every aspect of our business.</p>' +
      '<p>Our passion for innovation is deeply ingrained in our DNA, influencing everything from product development to marketing strategies and business expansion. This recognition is a reflection of the hard work and dedication of our talented team, who continually strive to deliver cutting-edge solutions that redefine the online gambling landscape.</p>' +
      '<p>As we celebrate this achievement, we’d like to express our gratitude to SlotsWise Gaming Awards for acknowledging our innovation journey!</p>',
    date: '12.01.2024',
  },
  {
    title: 'Double triumph at EiGE Awards: Two wins secured!',
    img: DoubleTriumphImg,
    background: DoubleTriumphBg,
    shortDescription:
      'In a resounding affirmation of excellence, Evoplay has once again proudly emerged as a dominant force.',
    description:
      '<p><h3>In a resounding affirmation of excellence, Evoplay has once again proudly emerged as a dominant force. This time, we have secured wins in two highly coveted categories at the prestigious <a href="https://hipther.com/events/egc/eige-awards/" target="_blank" rel="noreferrer">EiGE Awards</a> (The European iGaming Excellence Awards), solidifying our reputation as an industry leader.</h3></p>' +
      '<p>The honours bestowed upon Evoplay were none other than the titles of <strong>Best iGaming Supplier in 2023</strong> and <strong>Best Mobile Gaming Provider in 2023.</strong></p>' +
      "<p>This dual victory serves as a testament to Evoplay's relentless commitment to innovation and creating groundbreaking products. It inspires us to continue following our mission of redefining the future of iGaming through state-of-the-art technologies and an unwavering focus on delivering exceptional online entertainment.</p>",
    date: '02.11.2023',
  },
  {
    title: 'Meet Evoplay at October’s top industry events',
    img: MeetEvoplayImg,
    background: MeetEvoplayBg,
    shortDescription:
      'We have already pinned the map for the Evoplay team business travels in October - meet us at SBC Summit Latinoamérica',
    description:
      '<p><h3>We have already pinned the map for the Evoplay team business travels in October - meet us at SBC Summit Latinoamérica (October 31-November 2) and the European Gaming Congress (October 30-31).</h3></p>' +
      "<p>While we are planning the details of our trips, the best moment to schedule a meeting has arrived. Drop us a line at <a href='mailto:business@evoplay.games'>business@evoplay.games</a> if you're up for a chat!</p>" +
      '<p>See you soon!</p>',
    date: '05.10.2023',
  },
  {
    title: 'Best Online Casino Provider',
    img: BestOnlineCasinoImg,
    background: BestOnlineCasinoBg,
    shortDescription:
      'We have already pinned the map for the Evoplay team business travels in October - meet us at SBC Summit Latinoamérica',
    description:
      '<p><h3>In the fast-paced and ever-evolving iGaming world, staying at the top of the industry is no small feat. It takes dedication, innovation, and a passion for delivering unparalleled experiences to players and profit-boosting content to partners.</h3></p>' +
      '<p>Here at Evoplay, we strive for excellence in all that we do, and our recent win at the <a href="https://hipther.com/events/cee/gamingtech-awards-2023/">GamingTECH Awards</a> as <b>Best Online Casino Provider in CEE 2023</b> has affirmed our commitment to providing first-class online gaming products.</p>' +
      '<p>The feeling of achieving this recognition is simply unbeatable. We extend our deepest gratitude to the event organisers and also owe a massive thank you to our partners and supporters.</p>' +
      '<p>Cheers to this milestone, and we set off to continue raising the bar of business excellence!</p>',
    date: '03.10.2023',
  },
  {
    title: 'Innovation of the Year',
    img: InnovationImg,
    background: InnovationBg,
    shortDescription:
      'Out-of-the-box approach pays off: Evoplay wins Industry Innovation of the Year',
    description:
      '<p><h3>Out-of-the-box approach pays off: Evoplay wins Industry Innovation of the Year.</h3></p>' +
      "<p>Evoplay's innovative spirit, cutting-edge products, and commitment to pushing the boundaries of iGaming have paid off in the most spectacular way. Today, we proudly announce our recent <b>Industry Innovation of the Year</b> award at the <a href='https://sbcevents.com/sbc-awards/'>SBC Awards</a> 2023. It is not just a validation of our achievements but also an inspiration to continue developing new groundbreaking experiences.</p>" +
      "<p>The team at Evoplay was thrilled to be present at the SBC Summit Barcelona, where the award ceremony took place. We extend our heartfelt gratitude to the SBC Awards for recognising our efforts and contributions to the industry. This achievement would not have been possible without our team's dedication and the trust and support of our valued partners. We are more inspired than ever to live up to our slogan - <i>The Future of iGaming. Today.</i></p>",
    date: '26.09.2023',
  },
  {
    title: 'Evoplay shines with 2 shortlists at the EiGE Awards',
    img: Shortlists2Img,
    background: Shortlists2Bg,
    shortDescription:
      'Evoplay has secured nominations in two prominent categories at the esteemed EiGE Awards',
    description:
      '<p><h3>Evoplay has secured nominations in two prominent categories at the esteemed <a href="https://hipther.com/events/egc/eige-awards/">EiGE Awards</a>. These nominations stand as a testament to our dedication to providing top-notch gaming solutions:</h3></p>' +
      '<ul>' +
      '<li>Best iGaming Supplier in 2023;</li>' +
      '<li>Best Mobile Gaming Provider in 2023.</li>' +
      '</ul>' +
      '<p>Being shortlisted in such prestigious categories is a genuine honour for us, and we are profoundly grateful for each and every vote that has supported Evoplay. We eagerly anticipate the award ceremony and the opportunity to celebrate the iGaming excellence!</p>',
    date: '22.09.2023',
  },
  {
    title: 'Four shortlists, four times lucky',
    img: FourShortlistsImg,
    background: FourShortlistsBg,
    shortDescription:
      'We are on cloud nine with four fantastic nominations at the SBC Awards Latinoamerica 2023!',
    description:
      '<p>We are on cloud nine with four fantastic nominations at the <a href="https://sbcevents.com/en/sbc-awards-latinoamerica/">SBC Awards Latinoamerica 2023</a>! Evoplay is shortlisted for the titles of <b>Employer of the Year, Rising Star in Sports Betting</b> (thanks to our dazzling <i>Football Pack</i>), <b>Industry Innovation of the Year</b> (again, courtesy of the revolutionary Football Pack), and the grand title of <b>Casino & iGaming Supplier of the Year</b>.</p>' +
      '<p>We are counting down the days to the big event and want to extend our gratitude to the esteemed judges for selecting us as nominees!</p>',
    date: '08.09.2023',
  },
  {
    title: 'Evoplay sweeps 7 nominations at the SBC Awards 2023',
    img: 'https://evoplay.games/wp-content/uploads/2023/08/7shorts-373х251.jpg',
    background: EvoplaySweeps7Bg,
    shortDescription:
      'We are absolutely thrilled to share our latest achievement',
    description:
      '<p><h3>We are absolutely thrilled to share our latest achievement: Evoplay has once again taken the spotlight with a series of prestigious nominations! We\'re honoured to be shortlisted for the upcoming <a href="https://sbcevents.com/sbc-awards">SBC Awards</a> 2023 in 7! categories.</h3></p>' +
      'The nominations we are contending in are:' +
      '<ul>' +
      '<li aria-level="1">Employer of the Year;</li>' +
      '<li aria-level="1">Leader of the Year - our CCO Vladimir Malakchi;</li>' +
      '<li aria-level="1">Casino/Slots Developer of the Year - thanks to our outstanding Football Pack;</li>' +
      '<li aria-level="1">Rising Star in Sports Betting Innovation and Software - Football Pack and our football-themed hit <a href="/games/penalty_shoot_out">Penalty Shoot-out</a>;</li>' +
      '<li aria-level="1">Innovation in Mobile;</li>' +
      '<li aria-level="1">Innovation in Casino Entertainment - Football Pack and Penalty Shoot-out;</li>' +
      '<li aria-level="1">Industry Innovation of the Year - Football Pack and Penalty Shoot-out.</li>' +
      '</ul>' +
      '<p>With excitement growing, we eagerly anticipate the upcoming awards ceremony. This recognition fuels our passion for pushing the boundaries of gaming excellence. Stay tuned for the latest updates on this remarkable journey!</p>',
    date: '10.08.2023',
  },
  {
    title: 'Evoplay scores a double win - gold and bronze',
    img: 'https://evoplay.games/wp-content/uploads/2023/08/01_720х485.jpg',
    background: EvoplayScoresBg,
    shortDescription:
      'Evoplay has hit a double win at two high-profile iGaming industry awards, and we’re pumped!',
    description:
      '<p><h3>Evoplay has hit a double win at two high-profile iGaming industry awards, and we’re pumped! Our cross-channel Football Pack snagged the bronze as B2B Marketing Campaign at the EGR Marketing &amp; Innovation Awards. We also rocked the Innovation in Mobile category at the EGR B2B Awards.</h3></p>' +
      '<p>We’re beyond grateful for recognising us as true industry innovators, as it’s the best appreciation of our revolutionary products and an offbeat approach to marketing.</p>' +
      '<p>Cheers to more leading-edge games and groundbreaking solutions ahead!</p>',
    date: '02.08.2023',
  },
  {
    title: 'Evoplay secures multiple award nominations',
    img: 'https://evoplay.games/wp-content/uploads/2023/07/373х251-32.jpg',
    background: EvoplaySecuresBg,
    shortDescription:
      'We are thrilled to announce that Evoplay has once again gained recognition through a series of remarkable shortlistings!',
    description:
      '<p><h3>We are thrilled to announce that Evoplay has once again gained recognition through a series of remarkable shortlistings!</h3></p>' +
      '<p>It is truly an honour for us to be selected as nominees for prestigious awards at the <a href="https://hipther.com/events/cee/gamingtech-awards-2023/">GamingTECH Awards</a>. We eagerly anticipate the results, as we are vying for the titles of <strong>Best Online Casino Provider in the CEE</strong>, <strong>Rising Star - Online Casino Technology in CEE</strong>, and <strong>Online Casino Innovator in CEE</strong>. Furthermore, we are ecstatic to share that our innovative offerings have secured us a well-deserved spot on the shortlist for the <a href="https://sigma.world/asia/awards/">SiGMA Asia Awards 2023</a>. This recognition comes in two highly sought-after categories: <strong>Integrated Game Provider of the Year</strong> and <strong>Table Game Provider of the Year</strong>. We are especially proud of our captivating creation, <a href="/games/andar_nights">Andar Nights</a>, which has contributed to our inclusion in the latter category.</p>' +
      '<p>The anticipation is building, and we can’t wait to learn the final results!</p>',
    date: '06.07.2023',
  },
  {
    title: 'Two wins scored at the EGR Marketing & Innovation Awards',
    img: 'https://evoplay.games/wp-content/uploads/2022/06/site-720.jpg',
    background: TwoWinsBg,
    shortDescription: 'In everything we do, we strive for excellence.',
    description:
      '<p><h3>In everything we do, we strive for excellence. When it comes to marketing, we double down our efforts to make it seamlessly flawless!</h3></p>' +
      '<p>Evoplay\'s large-scale marketing campaigns have always created quite a stir, generating much buzz in the iGaming industry. Our commitment to making our creative games more visible to the audience has resulted in winning two trophies at the <span style="font-weight: 600">EGR Marketing and Innovation Awards</span>. We are super excited to announce our wins in the <span style="font-weight: 600">Supplier Marketing Campaign</span> and <span style="font-weight: 600">B2B Marketing Campaign categories!</span></p>' +
      '<p>Nothing beats the taste of two victories! We are grateful for the recognition and appreciate every vote that was cast for us!</p>',
    date: '21.06.2023',
  },
  {
    title: 'Evoplay scoops Slot Game Provider of the Year honours',
    img: 'https://evoplay.games/wp-content/uploads/2023/06/720х485-7.jpg',
    background: EvoplayScoopsBg,
    shortDescription:
      'Evoplay triumphs as Slot Game Provider of the Year at SiGMA Americas Awards 2023!',
    description:
      '<p><h3>Evoplay triumphs as Slot Game Provider of the Year at <a href="https://sigma.world/americas/">SiGMA Americas</a> Awards 2023!</h3></p>' +
      '<p>This recognition marks a monumental milestone for our team and showcases our unwavering commitment to delivering exceptional gaming experiences.</p>' +
      '<p>As we joined the largest iGaming event in Latin America, held in São Paulo, Brazil, little did we know that this journey would become a truly extraordinary one for Evoplay! Our win represents a prestigious accolade and validates our pursuit of innovation, quality, and player satisfaction. It is a testament to the dedication and passion of every individual on our team who leverages cutting-edge technology, immersive storytelling, and groundbreaking design to deliver innovative games.</p>' +
      '<p>We extend our deepest gratitude to the event organisers and the entire iGaming community for their unwavering support.</p>' +
      '<p>Stay tuned as we bring you more thrilling games, captivating features, and groundbreaking advancements. Together, let’s create the future of iGaming!</p>',
    date: '20.06.2023',
  },
  {
    title: 'Mega Greatest Catch is shortlisted as Slot to Watch',
    img: 'https://evoplay.games/wp-content/uploads/2023/05/373х251-23.jpg',
    background: MegaGreatestBg,
    shortDescription:
      'Mega Greatest Catch, one of Evoplay’s top-performing titles, made a real splash in the pond of iGaming entertainment.',
    description:
      '<p><h3><a href="/games/mega_greatest_catch">Mega Greatest Catch</a>, one of Evoplay’s top-performing titles, made a real splash in the pond of iGaming entertainment. It reeled in players’ attention, riding the tide with its trendy theme and gamification-filled mechanic, which resulted in our partners’ growing metrics.</h3></p>' +
      '<p>Catch of the day: the game has made it to the shortlist of the Slot to Watch category at the CasinoBeats Game Developer Awards! This recognition is a testament to the dedication that our team has put into creating an exceptional gaming experience for players.</p>' +
      '<p>We are proud of the success of Mega Greatest Catch and grateful to the judges for acknowledging our innovative approach to developing titles that capture the imaginations of players worldwide. We eagerly await the results of the CasinoBeats Game Developer Awards and continue setting the hook for more successful titles! </p>',
    date: '02.05.2023',
  },
  {
    title: 'Game Promotional Methods to Boost Casino Metrics. Part 2',
    img: 'https://evoplay.games/wp-content/uploads/2023/04/373х251-18.jpg',
    background: GamePromotional2Bg,
    shortDescription: 'Ways to offer promotions',
    description:
      '<p>It both matters - <i>what</i> and <i>how</i>. In the <a href="/newsUpdates/news/Game%20Promotional%20Methods%20to%20Boost%20Business%20Metrics.%20Part%201">previous article</a>, we have discussed a bunch of tools that help increase player involvement and enhance loyalty. In this part, we will consider ‘<i>how’ - </i>surefire online casino promotion delivery methods that evoke player incentives to explore the iGaming title.</p>' +
      '<h1>Ways to offer promotions</h1>' +
      '<p>To benefit from a game to a maximum, casino websites can use their total capacity for promotion. Here are some of the most effective methods for making a game visible and exposed to an end-user.</p>' +
      '<img src="http://evoplay.games/wp-content/uploads/2023/04/Promo-campaigns_-1.png" alt="" />' +
      '<h3>Banners</h3>' +
      '<p>Colourful and visually attractive banners with high-quality images and animations are an effective solution to catch a player’s eye and one of the ways to promote casino games. A banner’s design plays a crucial role as Internet users often have a so-called ‘banner blindness’ because of the considerable number of ads available on the web. To avoid it, casinos should consider every single aspect of a banner: a colour palette, its size, font, placement, etc. Moreover, if a banner looks like a simple product advert, it shatters users’ trust, and they don’t want to click it.</p>' +
      '<p>The first step is to pick the colours based on the impact they have on users and marketing tips. For example, according to <a href="https://www.creatopy.com/blog/display-ad-design-statistics/">Creatopy</a>, white, black, red and blue are the most popular for ads as they show the best performance. However, every industry has different specifics concerning a banner’s visuals, font and size, which should be taken into account. But one thing is for sure: to increase a banner’s click-through rate (CTR), it should have a clear call to action (CTA), stimulating a player to follow the link.</p>' +
      '<p>The type of content - static, animated or video - also depends on an ad’s purpose, promoted product and a banner’s placement on a website. In general, the most used designs for ads are as follows:</p>' +
      '<img src="http://evoplay.games/wp-content/uploads/2023/04/Graphic-1.png" alt="" />' +
      '<h3>Widgets</h3>' +
      '<p>A web widget is another performing tool that can assist in promoting casino games to players in a natural way. It is a small page embedded in a website’s interface, which functions as a separate application. For example, a widget with a casino game can be placed in a lobby on sports betting sites. It is a win-win situation for both a gambler and a casino. While players can diversify their gambling experience with a new type of entertainment, casino operators increase their gaming revenue.</p>' +
      '<p>The widget design should be eye-catching, displaying the most appealing game features to entice players thus acting as a casino promotion tool.</p>' +
      '<h3>Sections and game categorisation</h3>' +
      '<p>Categorising casino games is one of the easiest methods to make them more visible to the audience and stand out among other titles. Moreover, it is an intuitive casino game advertising, looking natural to website visitors.</p>' +
      '<p>A ‘Top games’ category, for example, is a surefire way to promote a game and increase players’ interest in it. Gamblers often search for new iGaming products to try and are ready to follow recommendations. The best or most popular games section is expected to include players’ top picks. It motivates other gamblers to try the recommended titles by themselves.</p>' +
      '<p>However, ‘Top games’ isn’t the only category to attract players. The power of exclusivity allows sparking even more interest. Such products make users feel unique, privileged, involved in something limited. Thus, the ‘Exclusive games’ section lures most players as it creates high value. They are more likely to choose a game from this list as it is associated with something special.</p>' +
      '<p>One more method to prioritise a game and put focus on it is to add the title to a separate tab on an online casino website. It adds value to the game, makes it more exposed to players and indicates its exclusiveness.</p>' +
      '<h3>Creative icons</h3>' +
      '<p>Well-designed icons can help to highlight a game, help with conversion rate optimisation, and generating more revenue from it. To create an icon that everyone wants to click, use animated, bright and aesthetically pleasing designs. Casino game icons also can include the words describing a title’s category - ‘top’, ‘exclusive’, ‘new’, etc.</p>' +
      '<h3>Customised newsletters and push notifications</h3>' +
      '<p>As mentioned above, consumers want to feel themselves ‘special’. Different types of messages and notifications with personalised offers are an excellent opportunity to put a spotlight on a game. An email or a push notification inviting a player to try a new slot, for instance, specifying that it is a special proposition, can result in a <b>high CTR rate</b>. Such messages activate a sense of curiosity and have an element of urgency. Players feel gratified by a casino because they are those ‘lucky people’ belonging to a small circle of ‘the elite’.</p>' +
      '<img src="http://evoplay.games/wp-content/uploads/2023/04/Visual_2.png" alt="" />' +
      '<h3>Promotion via influencers</h3>' +
      'Streamers and celebrities became powerful online casino games promotion channel. A personal brand allowed them to create hype around games with minimum effort.' +
      '<blockquote style="color: #0099FA"><b><i>89% of marketers have confirmed that ROI from influencer marketing is comparable to or even better than other marketing channels.</i></b><b><i>BigCommerce</i></b></blockquote>' +
      '<p>Streamers, social media influencers and celebrities can promote a casino game just by playing it and streaming this to the audience of viewers. Such a live presentation of a game, examination of its features and storyline, as well as the trust in an influencer’s choice can raise its conversion rate and be one of the most effective casino promotions as players want to try the title themselves.</p>' +
      '<p>Based on Evoplay’s experience, casino game marketing strategies involving campaigns with streamers perform pretty well - <b><i>x5 growth for a game</i></b> and <b><i>+30% in the number of players</i></b>. Running approximately <i>45 streams</i> of our games with the help of online casino influencers results in reaching <b><i>16.5 million potential players</i></b>.</p>' +
      '<p>A game is a core of the iGaming business and its main component. Casino promotion ideas help to capitalise on it. By increasing a game’s metrics, an operator increases a casino’s performance and, of course, its revenue.</p>' +
      '<p>Ready to take your casino performance to the next level? Don’t hesitate to give a shot at these casino game promotion tips! </p>',
    date: '10.04.2023',
  },
  {
    title: 'Game Promotional Methods to Boost Business Metrics. Part 1',
    img: 'https://evoplay.games/wp-content/uploads/2023/03/373х251-12.jpg',
    background: GamePromotionalBg,
    shortDescription:
      'A game is a tool used by casino operators to generate revenue.',
    description:
      '<p>A <em>game</em> is a tool used by casino operators to <em><strong>generate revenue</strong></em>. It is a building block upon which they will construct an entertainment platform - a casino website.</p>' +
      '<p>However, after being introduced to the market, every casino product needs promotion; otherwise, it risks remaining invisible to players and thus not profitable.</p>' +
      'A game’s performance can be estimated based on several KPIs:' +
      '<ul>' +
      '<li aria-level="1">gross gaming revenue (GGR);</li>' +
      '<li aria-level="1">net gaming revenue (NGR);</li>' +
      '<li aria-level="1">conversion rate;</li>' +
      '<li aria-level="1">bounce rate;</li>' +
      '<li aria-level="1">the lifetime value of players (LTV);</li>' +
      '<li aria-level="1">the average revenue per user;</li>' +
      '<li aria-level="1">cost per acquisition (CPA);</li>' +
      '<li aria-level="1">etc.</li>' +
      '</ul>' +
      '<p>Operators can apply a powerful toolkit to boost a gaming title’s metrics and turn players’ attention to it for increasing casino revenue.</p>' +
      '<h1>Game promotional offers every casino should use</h1>' +
      '<p>Being a part of the entertainment sector, the iGaming vertical has enormous potential to promote its products, making this process enthralling, full of fun, and profitable for players. Check out the most efficient methods for introducing an iGaming title, making it a top pick for players.</p>' +
      '<h3>Quests</h3>' +
      '<p>Quests have always been one of the most powerful gamification tools, appealing to almost every type of player: <i>killers, achievers, socialisers, and explorers</i>. Every player finds their own engagement factor in online game quests, and participating titles will never be overlooked.</p>' +
      '<p>At Evoplay, we developed an ultimate <b>QuestEvo tool</b>, allowing players to embark on exciting adventures and complete quests with rewards to earn prizes. It is a perfect marketing method aimed at increasing customer acquisition, retention, and loyalty. Our quest tool is an excellent addition to any casino’s game offerings, as players will be able to explore new worlds and in a variety of quest-based games.</p>' +
      '<p>Game-based motivation increases user engagement by 48%. We have created a tool that enhances the iGaming experience, by instilling aspiration and satisfaction in players through the achievement of predetermined goals. Players can navigate the competitive gaming world using only 3 buttons on the main Evo widget. By clicking the widget, a player will be transferred to the main screen, where they will see a list of available quests for the day. They can easily obtain the list of the Evoplay quest games as well as the rules for successful completion.</p>' +
      '<p><img src="http://evoplay.games/wp-content/uploads/2023/03/Quest.png" alt="" /></p>' +
      'Currently, we have 5 types of interactive game quests based on:' +
      '<ul>' +
      '<li aria-level="1">turnover (Bet Sum);</li>' +
      '<li aria-level="1">amount of wins;</li>' +
      '<li aria-level="1">amount of spins (with the bet amount and without it);</li>' +
      '<li aria-level="1">accumulated win sum;</li>' +
      '<li aria-level="1">the Free Spins game.</li>' +
      '</ul>' +
      '<p>QuestEvo tool - is an all-inclusive solution that doesn’t require any special technical configuration or additional materials from the casino side. It’s now ready for implementation and use!</p>' +
      '<p>Want a quest tool as a fun and engaging way to keep players coming back? Contact us for quests and discover how you can capitalise on them to the fullest!</p>' +
      '<h3>Tournaments</h3>' +
      '<p>Casino players enjoy tournaments for a variety of reasons. First and foremost, tournaments offer a chance to compete against other players to potentially win a significant prize. The competitive aspect of tournaments adds an extra layer of excitement and challenge players to rise to the top of the rankings. By promoting tournaments, casinos can significantly boost their key metrics and engage more players to compete for a prize pool.</p>' +
      '<p>Evoplay’s <b>Tournaments tool </b>is developed in such a way that participants experience maximum excitement while earning points, which they can later redeem for a share of a whopping prize fund. Casinos increase their engagement and retention rates through two types of this activity: local tournaments and network game tournaments, while also experiencing a spike in GGR, Bet Sum, etc. To offer players a great deal of choice, we include both slots and instant games in tournaments, with separate competitions for each.</p>' +
      'To make our online game tournaments even more efficient for our partners, we provide them with all of the necessary support and materials:' +
      '<ul>' +
      '<li aria-level="1">Promotional banners on the homepage and casino lobby of participating casinos.</li>' +
      '<li aria-level="1">Landing pages for Evoplay game tournaments.</li>' +
      '<li aria-level="1">Prime positioning of game/games included in the promotion section.</li>' +
      '<li aria-level="1">Promo banners placement.</li>' +
      '<li aria-level="1">SMS, email newsletters, push and in-box notifications.</li>' +
      '<li aria-level="1">Social media activity for casino game tournament promotion.</li>' +
      '</ul>' +
      '<p>Given all the groundwork and support we provide for this type of activity, our partners see impressive game tournament results, including increased brand exposure.</p>' +
      '<p><img src="http://evoplay.games/wp-content/uploads/2023/03/Graphic_2.png" alt="" /></p>' +
      '<h3>Jackpots</h3>' +
      '<p><strong>Jackpot</strong> casino games have proven to be a real top pick among players making them super excited to win big in one fell swoop. It makes them feel more invested in the process of gaming, which in turn heightens the excitement and positive anticipation they feel during gameplay.</p>' +
      '<p>Casinos benefit from big win jackpots in their key performance indicators when they use such titles. The enticing nature of game jackpot prizes increases player interest and engagement. According to recent data, almost <i>70%</i> of players increase their daily bets while taking part in jackpot campaigns. Consequently, it has a positive impact on Bet Count growth (<i>up to 50% of an average Bet Sum in </i><i>online game jackpots</i>). In addition, <i>47.5%</i> of players prefer to play <i>only</i> jackpot games.</p>' +
      '<p>Player favourite jackpots available within the casino offer are a surefire way to supercharge player loyalty and attract new players. Moreover, Evoplay jackpot games are a ready-made advertising tool for a casino. Nothing is more inspiring for players than seeing a screenshot of big wins, testimonials, and other proofs of scooping up a large profit.</p>' +
      '<p><img src="https://evoplay.games/wp-content/uploads/2023/03/Jackpots-1.png" alt="" /></p>' +
      '<p>The popularity of jackpots proves that there is a high demand for their variety in different formats. Both fixed and progressive jackpots can give a commercial boost to a casino. In light of this, we have created the <b>Evoplay Jackpot Widget</b> - a casino interface component that displays the jackpot amount in real-time. A casino can set the jackpot amount update and select one of 3 jackpot design skins, or request its own unique design.</p>' +
      '<h3>Bonus Buy feature</h3>' +
      '<p><b>Bonus Buy</b> games proved to be a huge hit with online casino players. Our research shows that Bonus Buy is a game performance booster, which is why we are constantly upgrading our already-released games with the feature.</p>' +
      '<p>Being the most efficient among special game features, Bonus Buy provides players with numerous benefits and online casinos - with added value. iGamers play Bonus Buy games because they have higher chances of winning, RTP and access to Free Spins or a bonus round available anytime. It results in more satisfied customers and increased loyalty to the casino.</p>' +
      '<p>The Evoplay Bonus Buy games demonstrate a striking result - a significant surge in almost all metrics. Look no further than our selection of Bonus Buy-based titles for constant increase in Average Bet, Rounds and GGR!</p>' +
      '<img src="https://evoplay.games/wp-content/uploads/2023/03/Visual_3-1.png" alt="" />' +
      '<h3>Branded games</h3>' +
      '<p>Unique <strong>game branding</strong> is an opportunity to show your brand exclusiveness to the audience. By adapting an existing game to reflect the casino’s aesthetic and promote brand recognition, a casino can tap into a new level of marketing previously only available to strong market performers.</p>' +
      '<p>Evoplay branded games are an efficient way to accentuate your casino brand and stand out from the crowd. With the online casino competition growing by the day, such content is a non-traditional approach to positioning and presenting your iGaming platform. Branded online games profile a casino as one that differentiates and invests heavily in reaching a broader audience of players.</p>' +
      '<p><img src="http://evoplay.games/wp-content/uploads/2023/03/Branded-Games.png" alt="" /></p>' +
      '<p>In contrast to branded game development, we offer our partners branded casino games promotion, which is a ready tool for growing your popularity. At the end of it all, you’ll get exclusive content with any graphic component showcasing your brand. Just imagine having your company name appear in a bestseller gaming title: you reflect your brand personality, engage an audience, and target and attract new players - all in a natural way. Brand promotion games have a positive impact on a casino’s perception and image, thus turbocharging the metrics.</p>' +
      '<h3>Acquisition bonuses</h3>' +
      '<p><img src="https://evoplay.games/wp-content/uploads/2023/03/Bonuses-1.png" alt="" /></p>' +
      '<p>Online casino bonuses are one of the most effective types of promotions as they provide players with perks, which can increase their profit in a game as well as a casino’s GGR. Depending on a promotion’s goal, there are three types of bonuses that can be used to enhance a game’s performance.</p>' +
      '<p>The purpose of the acquisition bonus is to help a casino acquire new customers via a specific gaming title. Here are the most popular types of such bonuses.</p>' +
      '<p><b>A deposit/</b><b>no-deposit welcome bonus</b> is given to a newly registered user of an iGaming website and positively influences the conversion rate and average revenue per user. It can be rewarded after a player has deposited at an online casino or even without the obligatory balance replenishment. To boost a game, an operator can offer a welcome deposit bonus, which can be used only in it.</p>' +
      '<p><b>A promo code bonus </b>can be applied to a specific gaming product, allowing an operator to improve their metrics and track how many people participate.</p>' +
      '<h3>Retention bonuses</h3>' +
      '<p>To hold players’ attention to a particular game, a casino can use a wide variety of retention bonuses.</p>' +
      '<p>Casinos can provide<b> monthly/weekly/daily </b><b>game bonuses and rewards</b> to players to promote a gaming title. They are given in the form of free spins, deposit multipliers, cashback, etc. While end-users get more opportunities to win and stay on a website to take advantage of it, operators grow the SlotCount and GGR rates.</p>' +
      '<p>To engage high rollers in a game, casinos can make customised offers to VIP players. Operators adjust <b>high roller bonuses</b> to any game performance of which should be improved, rewarding players with Free Spins or other perks to use for bets.</p>' +
      '<p>Another way to promote a game is to provide players with <b>loyalty bonuses</b>, which can be applied to it. Thus, the most active players are rewarded, their loyalty is encouraged, and a casino title gets more new fans.</p>' +
      '<p>Usually, online casinos give<b> a cashback bonus </b>to return some part of the money that players have lost while betting on the website. However, cashback can also be given for a specific game. It stimulates players to choose this title among other games as the opportunity to get a refund appeals to them.</p>' +
      '<p><b>Special occasions/seasonal </b><b>bonus programs</b> for players are a great way to promote a gaming product, as they can be tied to a game’s theme. For example, to promote a winter-related slot, a casino announces a bonus dedicated to the winter holidays.</p>' +
      '<h3>Bonus components to promote a game</h3>' +
      '<p>A bonus package used for a game to boost casino performance can consist of several components.</p>' +
      '<p><b>Free Spins</b> are one of the most popular types of activities that engage casino customers. They can be given to gamblers for a wide range of completed tasks, for example, playing a specific slot, being a loyal customer, depositing, confirming an email and mobile phone number, winning in a contest, etc. Very often, gambling websites add them to a bonus, allowing players to use them only in a certain game.</p>' +
      '<p><img src="https://evoplay.games/wp-content/uploads/2023/03/Visual_1.png" alt="" /></p>' +
      '<p>To increase gamblers’ interest and LTV, casinos provide them with <b>reward multipliers</b>. A chance to multiply winnings inspires players to stay with the game and enjoy it for a long time. <b></b></p>' +
      '<p><b>Cash </b>is also a classic ingredient of bonus offers. Casinos encourage end-users to play a game by giving them a certain amount of money for bets in a promoted game.</p>' +
      '<p><b>The in-game currency</b> is another way to boost the LVT metric of a game. Points, coins, or other rewards, which can be spent on playing a specified title, motivate gamblers to try luck again.</p>' +
      '<p><b>Reward multipliers</b> are frequently used for a new slot promotion as players are attracted to the opportunity to double or triple winnings or increase them even more - by 100 times. That is why reward multiplier offers are a standard method to boost attention to a gaming product that has been recently introduced to the market.</p>' +
      '<p>The competitive iGaming environment does not allow for the underutilization of marketing tools. It’s high time to unleash the potential of available promotional methods and discover the opportunities to dominate the market.</p>' +
      '<p>Move to the <a href="/newsUpdates/news/Game%20Promotional%20Methods%20to%20Boost%20Casino%20Metrics.%20Part%202">second part of the article</a> to find out more about how to leverage the enormous power of game marketing tools.</p>',
    date: '24.03.2023',
  },
  {
    title: 'Star Guardians Gets Shortlisted for Online Slot of the Year',
    img: 'https://evoplay.games/wp-content/uploads/2023/01/720х485-2.jpg',
    background: StarGuardiansBg,
    shortDescription:
      'Evoplay online slots provider shortlisted for Slots Provider of the Year.',
    description:
      '<p><h3>We at Evoplay are thrilled to announce that we have been shortlisted for Online Slot of the Year at the <a href="https://www.globalgamingawards.com/london/2023/">Global Gaming Awards 2023 in London</a>, for our ground-braking title:<a href="/games/star_guardians">Star Guardians.</a></h3></p>' +
      '<p>At Evoplay, we strive to always bring the best gaming experience to players. We believe in providing innovative, immersive and entertaining gaming experiences, and we take great pride in the success of our online slots.</p>' +
      '<p>That is why we are extremely honoured to have been shortlisted for the prestigious title. The award ceremony will take place on February 6th and will recognize the best online slot games from all over the world.</p>' +
      '<p>The nomination is a testament to the hard work, dedication and creativity of our team. We have put countless hours into developing and improving our online slots, and we are delighted to be recognised for our efforts.</p>' +
      '<p>This nomination is also a reflection of the incredible loyalty and dedication of our players and Key Account Managers. We are looking forward to the Global Gaming Awards ceremony, and we hope that we can bring home the trophy for Online Slot of the Year. We will keep you updated on our progress, and we thank you for your continued support and enthusiasm.</p>' +
      '<p>Please drop us an <b>e-mail at <a href="mailto:business@evoplay.games">business@evoplay.games</a></b> if you’re interested in integrating <a href="/games/star_guardians">Star Guardians</a> or want to get involved in promotional campaigns for this game.</p>',
    date: '05.01.2023',
  },
];
