import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { findPatchPages } from 'utils/pages';

import { PAGE_PATH } from 'components/config/pages';

import styles from './Item.module.scss';

const propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};

const Item = ({ img, title, date, category }) => (
  <Link
    className={styles.wrapper}
    to={findPatchPages(PAGE_PATH.NEWS_ITEM)
      .replace(':category', category)
      .replace(':item', title)}
  >
    <img src={img} alt="news img" />
    <div className={styles.content}>
      <div className={styles.title}>{title}</div>
      <div className={styles.date}>{date}</div>
    </div>
  </Link>
);

Item.propTypes = propTypes;
export default Item;
