export const formatDate = (date) => {
  let d = date.getUTCDate();
  let m = date.getUTCMonth() + 1;
  let y = date.getUTCFullYear();
  return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
};

export const formatDateTime = (date) => {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  return `${day}.${month}.${year} ${hours}:${minutes} (UTC)`;
};

export const formatViewDate = (date) => {
  let d = date.getUTCDate();
  let m = date.getUTCMonth() + 1;
  let y = date.getUTCFullYear();
  return '' + (d <= 9 ? '0' + d : d) + '.' + (m <= 9 ? '0' + m : m) + '.' + y;
};

export const dateStringToDate = (dateStr) => {
  const separateStr = dateStr.split('.');
  return new Date(separateStr[2], separateStr[1], separateStr[0]);
};

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const weekdaysName = {
  shorthand: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  longhand: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
};

export const getMonthName = (monthNumber) =>
  monthNames[monthNumber > 11 ? 0 : monthNumber];

export const differenceDateTime = (date1, date2) => {
  if (date2 === undefined) date2 = new Date();
  const d1 = new Date(typeof date1 == 'string' ? date1 : date1.getTime()),
    d2 = new Date(typeof date2 == 'string' ? date2 : date2.getTime());
  let Y, M, D, ms, h, m, s;
  M = 0;
  let d = new Date(d1.getTime());

  // eslint-disable-next-line no-constant-condition
  while (true) {
    d.setMonth(d.getMonth() + 1);
    if (d.getTime() < d2.getTime()) M++;
    else {
      d.setMonth(d.getMonth() - 1);
      break;
    }
  }

  Y = parseInt(M / 12);
  M = M - Y * 12;
  ms = d2.getTime() - d.getTime();
  D = parseInt(ms / 86400000);
  ms = ms - D * 86400000;
  h = parseInt(ms / 3600000);
  ms = ms - h * 3600000;
  m = parseInt(ms / 60000);
  ms = ms - m * 60000;
  s = parseInt(ms / 1000);

  return {
    year: Y,
    month: M,
    date: D,
    hour: h,
    minute: m,
    second: s,
  };
};
