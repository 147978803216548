import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import { sidebarSelector } from 'store/slices/sidebar';

import { findPatchPages } from 'utils/pages';

import { PAGE_PATH } from 'components/config/pages';

import { ReactComponent as ShortLogo } from 'images/Logo/Logo.svg';
import { ReactComponent as LogoWitchText } from 'images/Logo/LogoWitchText.svg';

import styles from './Logo.module.scss';

const Logo = () => {
  const isVisibleSidebar = useSelector(sidebarSelector.getVisible);

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.hideSidebar]: !isVisibleSidebar,
      })}
    >
      <Link to={findPatchPages(PAGE_PATH.HOME)}>
        {!!isVisibleSidebar && <LogoWitchText />}
        {!isVisibleSidebar && <ShortLogo width={33} />}
      </Link>
    </div>
  );
};

export default Logo;
