import React from 'react';

import useAccessView from 'hooks/tournaments/accessView';
import useGetTournaments from 'hooks/tournaments/tournaments';

import { findPatchPages } from 'utils/pages';
import { getTournamentsByStatus } from 'utils/tournaments';

import HomeComponentsLayout from 'components/Layouts/HomeComponents/HomeComponentsLayout';
import Banner from 'components/pages/Tournaments/Banner/Banner';

import { PAGE_PATH } from 'components/config/pages';
import { STATUSES, getType } from 'components/config/tournaments';

const Tournament = () => {
  const tournamentEvents = useGetTournaments();
  const hasAccessView = useAccessView();

  const running = tournamentEvents
    ? getTournamentsByStatus(tournamentEvents, STATUSES.running)
    : [];

  const comingSoon = tournamentEvents
    ? getTournamentsByStatus(tournamentEvents, STATUSES.comingSoon)
    : [];

  const tournament = running[0]?.event || comingSoon[0]?.event;

  if (!tournament || !hasAccessView) {
    return null;
  }

  return (
    <HomeComponentsLayout
      title="B2B Tournament"
      linkPath={findPatchPages(PAGE_PATH.TOURNAMENTS)}
      linkTitle="All B2B Tournaments"
    >
      <Banner item={tournament} category={getType[tournament.eventStatus]} />
    </HomeComponentsLayout>
  );
};

export default Tournament;
