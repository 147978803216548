/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import { findPatchPages } from 'utils/pages';

import { PAGE_PATH } from 'components/config/pages';

import styles from 'components/pages/Contents/Contents.module.scss';

const WebsiteTerms = () => (
  <div>
    <div className={styles.metaData}>
      <span></span>
      <span>Effective date: 20 March 2024.</span>
    </div>
    <h3 className={styles.header}>The Foreword</h3>
    <p>
      These Website Terms (hereinafter the “Terms”) govern and regulate your
      rights and obligation within the framework of your use of this website{' '}
      <Link to="/">{location.origin}</Link> (hereinafter the “Website”). By
      visiting or using the Website, you hereby signify that you have read,
      understood and agree to be bound by these Terms. Please note that any
      actions on the Website that are made by using your Internet connection or
      electronic device, unless you prove otherwise, shall be deemed as have
      been taken by you.
    </p>
    <h3 className={styles.header}>Information about us</h3>
    <p>
      This Website is owned and maintained by Evoplay Entertainment B.V., the
      company incorporated in Curaçao, with its registered number 146580, with
      its registered address at Fransche Bloemweg 4, Willemstad, Curacao
      (hereinafter the “Company”, “we”, “us”, “our”). Evoplay Entertainment B.V.
      is licensed by e-gaming Sublicense No. 8048/JAZ issued by Antillephone
      N.V. (Master License Holder). The verification of Sublicense can be made{' '}
      <a
        href="https://validator.antillephone.com/validate?domain=evoplayentertainment.com&seal_id=cc81f3360fce83eb803d6fa851045874713eca3cc300fdf81e3162f68c03be0ded34b728833953659ff7875a1924b90b&stamp=4a988503cd1d1ba69054903e4f90d82e"
        target="_blank"
        rel="noopener noreferrer"
      >
        via validation seal
      </a>
      .
    </p>
    <p>
      Should you have any questions, please, contact us via e-mail address{' '}
      <a href="mailto:info@evoplay.games">info@evoplay.games</a> and we will
      redirect your request to the appropriate department.
    </p>
    <h3 className={styles.header}>Revisions of these Terms</h3>
    <p>
      We can change, amend, modify, or correct these Terms. In case of any
      changes, amendments, modifications, or corrections, we will post the
      revised version of the Terms on the Website and change their effective
      date. It is your responsibility to check these Terms for any changes
      whenever you access the Website. Your continued use of the Website after
      the revised Terms have entered into force shall be considered as a fact
      that you have read, understood and agree to be bound by the revised Terms.
    </p>
    <h3 className={styles.header}>Conditions of Use</h3>
    <p>
      You may use the Website only for lawful purposes and in accordance with
      these Terms. You agree not to use the Website:
    </p>
    <ul>
      <li>
        In any way that violates any applicable federal, state, local or
        international law or regulation;
      </li>
      <li>
        In any manner that involves transmitting or sending of, any advertising
        or promotional material, including any “junk mail”, “chain letter” or
        “spam” or any other similar solicitation;
      </li>
      <li>
        In any manner that involves impersonating or attempt to impersonate the
        Company, a Company`s employee, another user or any other person or
        entity (including, without limitation, by using e-mail addresses
        associated with any of the foregoing);
      </li>
      <li>
        In any manner that involves engaging in any other conduct that restricts
        or inhibits anyone’s use or enjoyment of the Website or our digital
        assets, or which, as determined by us, may harm the Company or users of
        the Website or expose them to liability;
      </li>
      <li>
        In the manner that is or may be considered as defamatory, harassing,
        pornographic, embarrassing, vulgar, malicious, harmful, threatening,
        indecent, derogatory, discriminatory, untrue, political, abusive,
        sexist, hateful, offensive, menacing, obscene, racist, profane.
      </li>
    </ul>
    <p>In addition, you agree not to:</p>
    <ul>
      <li>
        Use any robot, spider or another automatic device, process or means to
        access the Website for any purpose, including monitoring or copying any
        of the material on the Website;
      </li>
      <li>
        Use any manual process to monitor or copy any of the material on the
        Website or for any other unauthorized purpose without our prior written
        consent;
      </li>
      <li>
        Use the Website in any manner that could disable, overburden, damage, or
        impair the site or interfere with any other party’s use of the Website,
        including their ability to engage in real time activities through the
        Website;
      </li>
      <li>
        Use any device, software or routine that interferes with the proper
        working of the Website;
      </li>
      <li>
        Introduce any viruses, trojan horses, worms, logic bombs or other
        material which is malicious or technologically harmful;
      </li>
      <li>
        Attempt to gain unauthorized access to, interfere with, damage or
        disrupt any parts of the Website, the server on which the Website is
        stored, or any server, computer or database connected to the Website;
      </li>
      <li>
        Attack the Website via a denial-of-service attack or a distributed
        denial-of-service attack;
      </li>
      <li>
        Otherwise attempt to interfere with the proper working of the Website.
      </li>
    </ul>
    <h3 className={styles.header}>Intellectual Property</h3>
    <p>
      Unless these Terms provide otherwise, the copyright and other rights to
      all information, data, codes, interfaces, texts, drawings, design,
      publications, artworks, pictures, photographs, music, images, graphics,
      and materials (hereinafter the “Content”) that are displayed or otherwise
      presented on the Website are owned by the Company or licensed to the
      Company by its suppliers or licensors. You are permitted to use and
      download such Content or any other materials from the Website to your
      electronic device subject to the following terms and conditions:
    </p>
    <p>
      You use the Website and any Content it contains for your internal,
      personal, private use only;
    </p>
    <p>
      Except otherwise provided and permitted by applicable law or these Terms,
      you must not use, copy, reproduce, republish, post, broadcast or transmit
      any part of the Website or the Content it contains for any purpose without
      prior written permission of the Company. This includes, without
      limitation, not reproducing or storing any part of the Website or the
      Content it contains in any other web page or any public or private
      electronic retrieval system or service;
    </p>
    <p>
      Except as the Company expressly permits in these Terms, you shall not in
      any way modify or create derivative works from the Website`s Content;
    </p>
    <p>
      Any rights not expressly granted in these Terms or by the Company in
      writing are reserved.
    </p>
    <p>
      Please note that the use of our logos and trademark are governed by the
      Brand Assets Policy which can be found via the{' '}
      <Link to="/brand-assets">link</Link>.
    </p>
    <h3 className={styles.header}>Indemnification</h3>
    <p>
      You agree to defend, indemnify, and hold the Company harmless including
      the Company`s affiliates, subsidiaries, officers or agents from and
      against any claims, actions or demands, damages, losses, liabilities,
      judgments, settlements, costs or expenses (including attorneys’ fees and
      costs) arising directly or indirectly from or relating to:
    </p>
    <ul>
      <li>
        the breach of these Terms by you or anyone using your Internet
        connection or by using your electronic device;
      </li>
      <li>
        any claim, loss or damage experienced from your use or attempted use of
        (or inability to use) the Website;
      </li>
      <li>your violation of any law or regulation;</li>
      <li>
        or any other matter for which you are responsible under these Terms or
        law.
      </li>
    </ul>
    <p>
      You agree to fully cooperate with us in the defence of any demand, claim,
      action, settlement or compromise negotiations specified in this clause as
      requested by us.
    </p>
    <h3 className={styles.header}>Assignment</h3>
    <p>
      Except as expressly permitted herein, you may not assign any of the
      rights, interests or obligations under these Terms without the prior
      written consent of the Company, and any such assignment will be null and
      void. The Company may assign these Terms or any rights, interests or
      obligations hereunder without your consent.
    </p>
    <h3 className={styles.header}>Third-parties Websites and resources</h3>
    <p>
      The Website may contain links to the websites, services or resources which
      are owned and operated by third parties (hereinafter “external
      resources”). You follow these links/hyperlinks to the external resources
      at your own risk. These resources are outside our control and we bear no
      liability for the content of any external resources and bear no
      responsibility for anything that can happen as a result of your visit to
      external resources. We highly recommend you consult the “legal” section of
      the external resource (which include the “terms”) prior to its use.
    </p>
    <h3 className={styles.header}>Privacy Policy</h3>
    <p>
      The Company processes your personal data in accordance with the Website`s
      Privacy Policy. Please, read our{' '}
      <Link to={findPatchPages(PAGE_PATH.CONTENTS).replace(':page', 'privacy')}>
        Privacy Policy
      </Link>{' '}
      prior to use the Website and the provision of any personal data to us.
    </p>
    <h3 className={styles.header}>DISCLAIMER. LIMITATION OF LIABILITY</h3>
    <p>
      THE WEBSITE AND ITS CONTENT ARE PROVIDED ON AN “AS IS” BASIS, EXCEPT AS
      EXPRESSLY PROVIDED HEREIN, WE DISCLAIM ALL REPRESENTATIONS AND WARRANTIES
      OF ANY KIND EXPRESSED, IMPLIED OR STATUTORY, TO THE FULLEST EXTENT
      PERMISSIBLE PURSUANT TO APPLICABLE LAW, INCLUDING BUT NOT LIMITED TO,
      IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, NON-INFRINGEMENT OF
      THIRD-PARTY RIGHTS AND FITNESS FOR A PARTICULAR PURPOSE. THE COMPANY DOES
      NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE WEBSITE WILL BE SECURE,
      COMPLETE, UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR
      THAT THE WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES
      OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE WILL OTHERWISE MEET YOUR
      NEEDS OR EXPECTATIONS. THE COMPANY DOES NOT WARRANT OR MAKE ANY
      REPRESENTATIONS REGARDING THE USE OF OR THE RESULT OF THE USE OF THE
      MATERIAL IN THIS WEBSITE IN RESPECT OF ITS CORRECTNESS, ACCURACY,
      RELIABILITY, OR OTHERWISE. YOU ASSUME THE ENTIRE COST OF ALL NECESSARY
      SERVICING, REPAIR OR CORRECTION. THE FOREGOING EXCLUSION DOES NOT AFFECT
      ANY IMPLIED WARRANTY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE
      LAW. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
      INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL WE, OUR AFFILIATES OR OUR
      LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE
      LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR
      IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY
      WEBSITES LINKED TO IT, OR ANY CONTENT ON THE WEBSITE OR SUCH OTHER
      WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
      CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL
      INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF
      PROFITS, LOSS OF BUSINESS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA,
      AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR
      OTHERWISE, EVEN IF THE COMPANY OR OUR AUTHORIZED REPRESENTATIVE HAS BEEN
      NOTIFIED ORALLY OR IN WRITING OF THE POSSIBILITY OF SUCH DAMAGE. THE
      COMPANY WILL NOT BE LIABLE FOR ANY DAMAGES OR INJURY CAUSED BY, INCLUDING
      BUT NOT LIMITED TO, ANY FAILURE OF PERFORMANCE, ERROR, OMISSION,
      INTERRUPTION, DEFECT, AND DELAY IN OPERATION OR TRANSMISSION, COMPUTER
      VIRUS, OR LINE FAILURE.
    </p>
    <h3 className={styles.header}>Severability</h3>
    <p>
      If any provision, clause, phrase, word of these Terms is held to be
      invalid or unenforceable, such provision, clause, phrase, word shall be
      struck out and the remaining provisions, clauses, phrases, words shall
      remain enforceable.
    </p>
  </div>
);

export default WebsiteTerms;
