class StorageCollection {
  constructor(key, storageData) {
    this.key = key;
    this.storageData = storageData;
  }
  add(data) {
    const storageData = this.getAll();
    localStorage.setItem(this.key, JSON.stringify({ ...storageData, ...data }));
  }

  getAll() {
    return JSON.parse(localStorage.getItem(this.key)) || this.storageData;
  }
  clear() {
    localStorage.removeItem(this.key);
  }
}

export default StorageCollection;
