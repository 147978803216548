import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { viewModeSelector } from 'store/slices/viewMode';

import { dateStringToDate } from 'utils/date';
import { findPatchPages } from 'utils/pages';
import { VIEW_MODE } from 'utils/viewMode';

import AnimationLayout from 'components/Layouts/Animation/AnimationLayout';
import HomeComponentsLayout from 'components/Layouts/HomeComponents/HomeComponentsLayout';
import Item from 'components/pages/Home/News/Item/Item';
import Main from 'components/pages/Home/News/Main/Main';

import { News as NewsData } from 'components/config/news';
import { PAGE_PATH } from 'components/config/pages';
import { Updates as UpdatesData } from 'components/config/updates';

import styles from './News.module.scss';

const News = () => {
  const viewMode = useSelector(viewModeSelector.getViewMode);
  const countSize = viewMode === VIEW_MODE.SIMPLE ? 3 : 4;
  const data = useMemo(
    () =>
      NewsData.map((item) => ({ ...item, category: 'news' }))
        .concat(UpdatesData.map((item) => ({ ...item, category: 'updates' })))
        .sort((a, b) => dateStringToDate(b.date) - dateStringToDate(a.date)),
    [],
  );

  return (
    <HomeComponentsLayout
      linkPath={findPatchPages(PAGE_PATH.NEWS)}
      title="News and Updates"
      linkTitle="All News"
    >
      <AnimationLayout animation="fadeInUp">
        <div className={styles.wrapper}>
          <Main news={data[0]} />
          <div className={styles.newsItems}>
            {data.slice(1, countSize + 1).map((item, index) => (
              <Item key={index} {...item} />
            ))}
          </div>
        </div>
      </AnimationLayout>
    </HomeComponentsLayout>
  );
};

export default News;
