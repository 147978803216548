import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const userSelector = {
  getInfo: (state) => state.user.data,
};

export const { setUserInfo } = userSlice.actions;
export default userSlice.reducer;
