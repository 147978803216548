import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { setUserInfo } from 'store/slices/user';

import { getUserProfile } from 'services/UserService';
import { findPatchPages } from 'utils/pages';

import AppLayout from 'components/Layouts/App/AppLayout';
import ContentLayout from 'components/Layouts/Content/ContentLayout';
import OnBoarding from 'components/OnBoarding/OnBoarding';
import PrivateRoute from 'components/PrivateRoutes/PrivateRoutes';
import Sidebar from 'components/Sidebar/Sidebar';
import ToggleSidebar from 'components/Sidebar/ToggleSidebar/ToggleSidebar';
import TournamentPopup from 'components/TournamentPopup/TournamentPopup';
import NotFound from 'components/pages/NotFound/NotFound';

import { BASE_PATH, PAGE_PATH, Pages } from 'components/config/pages';

import 'styles/main.scss';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getUserProfile().then((data) => dispatch(setUserInfo(data)));
  }, []);

  return (
    <>
      <AppLayout>
        <Sidebar />
        <ContentLayout>
          <Routes>
            {Object.keys(Pages).map((key) => (
              <Route
                element={<PrivateRoute Component={Pages[key].element} />}
                path={Pages[key].path}
                key={key}
              />
            ))}
            <Route
              path={BASE_PATH}
              element={<Navigate to={findPatchPages(PAGE_PATH.HOME)} replace />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ContentLayout>
      </AppLayout>
      <ToggleSidebar />
      <OnBoarding />
      <TournamentPopup />
    </>
  );
};
export default App;
