import { api } from 'store/api/gameService/api';

import { formatViewDate } from 'utils/date';

import {
  getBackground,
  getIcon,
  getLogo,
  getSlider,
} from 'components/config/cloud';
import { prepareFiltersByQuery } from 'components/config/filters';
import { SortMap } from 'components/config/sort';

export const recipeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRecommended: build.query({
      query: () => '/game-service/api/v1/recommended',
      transformResponse: (response) =>
        response?.map((game) => ({
          ...game,
          ...getSlider(game.gameCode),
          icon: getIcon(game.gameCode),
          logo: getLogo(game.gameCode),
        })),
    }),
    getLatestReleases: build.query({
      query: ({ count }) => ({
        url: '/game-service/api/v1/latest-releases',
        params: { count },
      }),
      transformResponse: (response) =>
        response?.map((game) => ({
          ...game,
          icon: game?.icon ? getIcon(game.gameCode) : null,
        })),
    }),
    getComingSoon: build.query({
      query: ({ count }) => ({
        url: '/game-service/api/v1/coming-soon',
        params: { count },
      }),
      transformResponse: (response) =>
        response?.map((game) => ({
          ...game,
          icon: game?.icon ? getIcon(game.gameCode) : null,
        })),
    }),
    getRoadmap: build.query({
      query: (params) => ({
        url: '/game-service/api/v1/roadmap',
        params,
      }),
      transformResponse: (response) =>
        response?.map((game) => ({
          ...game,
          icon: game?.icon ? getIcon(game.gameCode) : null,
        })),
    }),
    getGamesList: build.query({
      query: ({ sort, filters, provider }) => {
        const queryObject = {
          url: '/game-service/api/v1/games',
          params: { provider },
        };
        if (sort && SortMap[sort]) {
          queryObject.params.sort = SortMap[sort];
        }
        if (filters && Object.keys(filters).length) {
          queryObject.params = {
            ...queryObject.params,
            ...prepareFiltersByQuery(filters),
          };
        }
        return queryObject;
      },
      transformResponse: (response) => ({
        ...response,
        games: response?.games?.map((game) => ({
          ...game,
          icon: game?.icon ? getIcon(game.gameCode) : null,
        })),
      }),
    }),
    getGameInfo: build.query({
      query: ({ gameCode }) => `/game-service/api/v1/games/${gameCode}`,
      transformResponse: (game) => ({
        ...game,
        icon: game?.icon ? getIcon(game?.gameCode) : null,
        logo: game?.logo ? getLogo(game?.gameCode) : null,
        background: game?.background ? getBackground(game?.gameCode) : null,
      }),
    }),
    getBetCurrency: build.query({
      query: ({ currency, gameCode }) => ({
        url: '/game-service/api/v1/bet-currency',
        params: { currency, game_code: gameCode },
      }),
    }),
    getRules: build.query({
      query: ({ game_code, lang }) => ({
        url: '/game-service/api/v1/rules',
        params: { game_code: game_code.join(','), lang: lang.join(',') },
      }),
    }),
    getPosts: build.query({
      query: (params) => ({
        url: '/game-service/api/v1/posts',
        params: {
          sort: 'publicationDate,desc',
          ...params,
        },
      }),
      transformResponse: (response) => ({
        ...response,
        content: response?.content?.map((item) => ({
          ...item,
          img: item.postImage,
          background: item.backgroundImage,
          date: formatViewDate(new Date(item.publicationDate)),
        })),
      }),
    }),
    getPost: build.query({
      query: (id) => ({
        url: `/game-service/api/v1/posts/${id}`,
      }),
      transformResponse: (response) => ({
        ...response,
        img: response.postImage,
        background: response.backgroundImage,
        date: formatViewDate(new Date(response.publicationDate)),
      }),
    }),
  }),
});

export const {
  useGetRecommendedQuery,
  useGetLatestReleasesQuery,
  useGetComingSoonQuery,
  useGetRoadmapQuery,
  useGetGamesListQuery,
  useGetGameInfoQuery,
  useGetBetCurrencyQuery,
  useGetPostsQuery,
  useGetPostQuery,
} = recipeApi;
