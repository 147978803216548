import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import { useGetPostsQuery } from 'store/api/gameService/recipe.api';
import { viewModeSelector } from 'store/slices/viewMode';

import { stripHtml } from 'utils/formatters';
import { findPatchPages } from 'utils/pages';
import { VIEW_MODE } from 'utils/viewMode';

import ArrowCircleLink from 'components/ArrowCircleLink/ArrowCircleLink';
import Loader from 'components/Loader/Loader';
import List from 'components/pages/NewsAndUpdates/List/List';

import { News as OldNews } from 'components/config/news';
import { PAGE_PATH } from 'components/config/pages';
import { Updates as OldUpdates } from 'components/config/updates';

import styles from './NewsAndUpdates.module.scss';

const NewsAndUpdates = () => {
  const viewMode = useSelector(viewModeSelector.getViewMode);
  const countSize = viewMode === VIEW_MODE.SIMPLE ? 3 : 4;

  const { isLoading, data } = useGetPostsQuery({ size: 100 });

  if (isLoading) {
    return <Loader />;
  }

  const Updates = data?.totalElements
    ? data?.content?.filter(({ postType }) => postType === 'UPDATE')
    : OldUpdates;

  const News = data?.totalElements
    ? data?.content?.filter(({ postType }) => postType === 'NEWS')
    : OldNews;

  return (
    <>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.title}>Tech and product updates</div>
          <ArrowCircleLink
            path={findPatchPages(PAGE_PATH.NEWS_CATEGORY).replace(
              ':category',
              'updates',
            )}
            title="All Updates"
          />
        </div>
        <div className={styles.updates}>
          <Link
            className={classNames(styles.main, styles.link)}
            to={findPatchPages(PAGE_PATH.NEWS_ITEM)
              .replace(':category', 'updates')
              .replace(':item', Updates[0].id ?? Updates[0].title)}
          >
            <img src={Updates[0].img} alt="main update" />
            <div className={styles.info}>
              <div className={styles.title}>{Updates[0].title}</div>
              <div className={styles.description}>
                {stripHtml(Updates[0].shortDescription)}
              </div>
              <div>{Updates[0].date}</div>
            </div>
          </Link>
          <div>
            {Updates.slice(1, countSize + 1).map((item, index) => (
              <Link
                className={classNames(styles.mainLink, styles.link)}
                key={index}
                to={findPatchPages(PAGE_PATH.NEWS_ITEM)
                  .replace(':category', 'updates')
                  .replace(':item', item.id ?? item.title)}
              >
                <img src={item.img} alt="news update" />
                <div className={styles.info}>
                  <div className={styles.title}>{item.title}</div>
                  <div>{item.date}</div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.newsSection}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.title}>News</div>
            <ArrowCircleLink
              path={findPatchPages(PAGE_PATH.NEWS_CATEGORY).replace(
                ':category',
                'news',
              )}
              title="All News"
            />
          </div>
          <div className={styles.list}>
            {News.slice(0, 8).map((item, index) => (
              <List {...item} key={index} category="news" />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsAndUpdates;
