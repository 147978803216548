import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import useCountdown from 'hooks/countdown';
import useContentScrollLock from 'hooks/disableScroll';
import PropTypes from 'prop-types';

import {
  useGetTournamentResetsQuery,
  useResetTournamentScoreMutation,
} from 'store/api/tournamentService/recipe.api';
import { userSelector } from 'store/slices/user';

import { findPatchPages } from 'utils/pages';

import ArrowCircleLink from 'components/ArrowCircleLink/ArrowCircleLink';
import Button from 'components/UI/Button/Button';

import { PAGE_PATH } from 'components/config/pages';
import { getGameUrl } from 'components/config/tournaments';

import { ReactComponent as ProfileCircle } from 'images/Icons/ProfileCircle.svg';
import { ReactComponent as Sandglass } from 'images/Icons/Sandglass.svg';

import styles from 'components/pages/Tournaments/Item/Item.module.scss';
import stylesLayout from 'components/pages/Tournaments/Tournaments.module.scss';

const propTypes = {
  item: PropTypes.shape({
    eventId: PropTypes.string.isRequired,
    eventName: PropTypes.string.isRequired,
    backgroundUrl: PropTypes.string,
    activeStatusFinishTime: PropTypes.string.isRequired,
  }).isRequired,
  player: PropTypes.shape({
    nickname: PropTypes.string,
  }),
  results: PropTypes.shape({
    position: PropTypes.number,
    score: PropTypes.number,
  }).isRequired,
};

const HeaderGame = ({ item, results, player = null }) => {
  const timeLeft = useCountdown(new Date(item.activeStatusFinishTime));
  const userInfo = useSelector(userSelector.getInfo);
  const [resetTournamentScore] = useResetTournamentScoreMutation();
  const { data: resets } = useGetTournamentResetsQuery(
    {
      event_id: item.eventId,
      user_id: userInfo?.id,
      email: userInfo?.email,
    },
    { skip: !item || !userInfo },
  );
  const focusedRef = useRef(false);
  useContentScrollLock(
    document.getElementById('content-wrapper'),
    focusedRef.current,
  );

  const handleRestart = useCallback(async () => {
    await resetTournamentScore({
      event_id: item.eventId,
      user_id: userInfo?.id,
      email: userInfo?.email,
    });
    location.reload();
  }, []);

  const gameUrl = getGameUrl(userInfo.id, userInfo.email);

  useEffect(() => {
    const checkFocus = () => {
      const iframe = document.getElementsByTagName('iframe')[0];
      focusedRef.current = iframe && document.activeElement === iframe;
    };

    const intervalId = setInterval(checkFocus, 100);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      className={styles.bg}
      style={{
        backgroundImage: `url(${item.backgroundUrl})`,
      }}
    >
      <div className={stylesLayout.wrapper}>
        <div className={styles.back}>
          <ArrowCircleLink
            path={findPatchPages(PAGE_PATH.TOURNAMENTS)}
            title="Go to all tournaments"
            direction="left"
          />
        </div>
        <div className={styles.gameWrapper}>
          <div className={styles.header}>
            <div className={styles.title}>{item.eventName}</div>
            <div className={styles.finish}>
              <div className={stylesLayout.chip}>
                <span className={stylesLayout.chipLabel}>
                  <Sandglass /> Ends in
                </span>
                <span className={stylesLayout.chipValue}>
                  {timeLeft
                    ? `${timeLeft.days}d : ${timeLeft.hours}h : ${timeLeft.minutes}m : ${timeLeft.seconds}s`
                    : '-'}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.game}>
            <iframe
              width="100%"
              height="100%"
              src={gameUrl}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Game Demo"
            />
          </div>
        </div>
      </div>
      {player && (
        <div className={styles.gameBar}>
          <div
            className={classNames(stylesLayout.wrapper, styles.gameBarWrapper)}
          >
            <div className={styles.results}>
              <div className={styles.resultsUser}>
                <ProfileCircle />
                ID {player?.nickname || ''}
              </div>
              <div className={styles.resultsPoints}>
                Points:
                <div className={styles.resultsPointsCell}>
                  {results?.score || 0}
                </div>
              </div>
              <div className={styles.resultsPoints}>
                Position:
                <div className={styles.resultsPointsCell}>
                  #{results?.position || '-'}
                </div>
              </div>
              {resets > 0 && (
                <div className={styles.resultsRestart}>
                  <Button className={styles.restartBtn} onClick={handleRestart}>
                    Restart <span>({resets || 0} times)</span>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

HeaderGame.propTypes = propTypes;
export default HeaderGame;
