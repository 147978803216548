import React from 'react';
import { useSelector } from 'react-redux';

import { useGetComingSoonQuery } from 'store/api/gameService/recipe.api';
import { viewModeSelector } from 'store/slices/viewMode';

import { findPatchPages } from 'utils/pages';
import { VIEW_MODE } from 'utils/viewMode';

import AnimationLayout from 'components/Layouts/Animation/AnimationLayout';
import HomeComponentsLayout from 'components/Layouts/HomeComponents/HomeComponentsLayout';
import Item from 'components/pages/Home/ComingSoon/Item/Item';

import { PAGE_PATH } from 'components/config/pages';

import styles from './ComingSoon.module.scss';

const ComingSoon = () => {
  const viewMode = useSelector(viewModeSelector.getViewMode);
  const countSize = viewMode === VIEW_MODE.SIMPLE ? 5 : 7;
  const { data } = useGetComingSoonQuery({ count: 7 });

  if (!data || !Array.isArray(data) || !data.length) {
    return null;
  }

  return (
    <HomeComponentsLayout
      linkPath={findPatchPages(PAGE_PATH.ROADMAP).replace(
        ':provider',
        'evoplay',
      )}
      title="Coming soon"
      linkTitle="Evoplay Roadmap"
      styleWrapper={styles.wrapper}
      styleHeader={styles.header}
    >
      <AnimationLayout animation="fadeInRight">
        <div className={styles.content}>
          {data.slice(0, countSize).map((item, index) => (
            <Item item={item} key={index} />
          ))}
        </div>
      </AnimationLayout>
    </HomeComponentsLayout>
  );
};

export default ComingSoon;
