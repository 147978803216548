import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { allGamesSelector, sortBy } from 'store/slices/allGames';

import Select from 'components/UI/Select/Select';

import { Sort } from 'components/config/sort';

const SortBy = () => {
  const dispatch = useDispatch();
  const value = useSelector(allGamesSelector.getSortBy);
  const handleChange = useCallback((_, value) => dispatch(sortBy(value)), []);

  return (
    <Select
      handleSelect={handleChange}
      title="Sort By"
      label="sortBy"
      items={Sort}
      value={value}
    />
  );
};

export default SortBy;
