import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { userSelector } from 'store/slices/user';

const useAccessView = () => {
  const userInfo = useSelector(userSelector.getInfo);

  return useMemo(
    () => userInfo?.email?.indexOf('@evoplay.tech') === -1,
    [userInfo],
  );
};

export default useAccessView;
