import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import classNames from 'classnames';

import { viewModeSelector } from 'store/slices/viewMode';

import { VIEW_MODE } from 'utils/viewMode';

import Button from 'components/UI/Button/Button';

import { BrandAssetsData } from 'components/config/brandAssets';
import { MascotData } from 'components/config/mascotData';

import { ReactComponent as LogoEvoplay } from 'images/BrandAssets/Evoplay/Logo.svg';
import { ReactComponent as VerticalEvoplay } from 'images/BrandAssets/Evoplay/Vertical.svg';
import { ReactComponent as HorizontalEvoplay } from 'images/BrandAssets/Evoplay/horizontal.svg';
import { ReactComponent as LogoSlotopia } from 'images/BrandAssets/Slotopia/Logo_Slotopia.svg';
import { ReactComponent as VerticalSlotopia } from 'images/BrandAssets/Slotopia/Vertical.svg';
import { ReactComponent as HorizontalSlotopia } from 'images/BrandAssets/Slotopia/horizontal.svg';
import { ReactComponent as ArrowCircleIcon } from 'images/Icons/ArrowCircle.svg';
import { ReactComponent as DownloadIcon } from 'images/Icons/Download.svg';

import styles from 'components/pages/BrandAssets/BrandAssets.module.scss';

const BrandAssets = () => {
  const { provider } = useParams();

  const viewMode = useSelector(viewModeSelector.getViewMode);
  const [currentSlide, setCurrentSlide] = useState(0);
  const nextSlide = useCallback(
    () =>
      setCurrentSlide(
        currentSlide === MascotData.length - 1 ? 0 : currentSlide + 1,
      ),
    [currentSlide],
  );
  const prevSlide = useCallback(
    () =>
      setCurrentSlide(
        currentSlide === 0 ? MascotData.length - 1 : currentSlide - 1,
      ),
    [currentSlide],
  );

  return (
    <>
      <div className={styles.bg}>
        <div className={classNames(styles.brandAssets, styles.content)}>
          <div className={styles.title}>Brand Assets</div>
          <div className={styles.description}>
            Follow these requirements so our logo always looks its best.
            <br />
            Please do not modify or distort our logo.
          </div>
          {provider === 'evoplay' ? (
            <LogoEvoplay height={81} />
          ) : (
            <LogoSlotopia height={81} />
          )}
          <Button
            variant="icon"
            startIcon={<DownloadIcon width={32} height={32} />}
            className={styles.btn}
            target="_blank"
            rel="noreferrer"
            component={Link}
            to={BrandAssetsData[provider].All}
            download
          >
            All Brand Assets
          </Button>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.logo}>
          <div className={classNames(styles.section, styles.horizontalLogo)}>
            {provider === 'evoplay' ? (
              <HorizontalEvoplay />
            ) : (
              <HorizontalSlotopia />
            )}
          </div>
          <div>
            <div className={styles.title}>
              horizontal logo,{viewMode === VIEW_MODE.SIMPLE ? <br /> : ' '}
              scale & clearspace
            </div>
            <div className={styles.description}>
              <p>
                We have developed two versions of the logo for dark and light
                backgrounds. Use the logo version that appears most prominently
                on your background.
              </p>
              <p>
                Do not insert any elements in the clearspace surrounding the
                logo, as illustrated in the image.
              </p>
            </div>
            <Button
              variant="icon"
              startIcon={<DownloadIcon width={32} height={32} />}
              className={styles.btn}
              target="_blank"
              rel="noreferrer"
              component={Link}
              to={BrandAssetsData[provider]['Horizontal Logo']}
              download
            >
              Horizontal Logo
            </Button>
          </div>
        </div>
        <div className={styles.logo}>
          <div>
            <div className={styles.title}>
              vertical logo
              {viewMode === VIEW_MODE.SIMPLE ? <br /> : null} & clearspace
            </div>
            <div className={styles.description}>
              <p>We have also designed a vertical version of the logo.</p>
              <p>
                Use this logo for layouts where it appears more cohesive, and
                remember to maintain the required clearspaces.
              </p>
            </div>
            <Button
              variant="icon"
              startIcon={<DownloadIcon width={32} height={32} />}
              className={styles.btn}
              target="_blank"
              rel="noreferrer"
              component={Link}
              to={BrandAssetsData[provider]['Vertical Logo']}
              download
            >
              Vertical Logo
            </Button>
          </div>
          <div className={styles.section}>
            {provider === 'evoplay' ? (
              <VerticalEvoplay />
            ) : (
              <VerticalSlotopia />
            )}
          </div>
        </div>
      </div>
      {provider === 'evoplay' && (
        <div className={styles.bg}>
          <div className={classNames(styles.mascot, styles.content)}>
            <div>
              <div className={styles.title}>Mascot</div>
              <div className={styles.description}>
                <p>The Evoplay mascot is Evo, portrayed as an owl.</p>
                <p>
                  Evoplay is a coined term combining &apos;evolution&apos; and
                  &apos;play&apos;. Owls symbolise wisdom, change,
                  transformation, evolved perspective, and new beginnings. They
                  embody the company transforming how people perceive iGaming.
                </p>
                <p>
                  We opted for a bird, as wings are symbols of flight, the
                  aspiration to ascend, and, historically, improvement.
                </p>
                <p>
                  Evo has traits of a fighter, an explorer, a guardian, and an
                  adventurer - always prepared for the game!
                </p>
              </div>
              <Button
                variant="icon"
                startIcon={<DownloadIcon width={32} height={32} />}
                className={styles.btn}
                target="_blank"
                rel="noreferrer"
                component={Link}
                to={BrandAssetsData[provider].Mascot}
                download
              >
                Mascot
              </Button>
            </div>
            <div className={classNames(styles.slider, styles.section)}>
              <ArrowCircleIcon
                onClick={prevSlide}
                className={styles.leftArrow}
              />
              <ArrowCircleIcon onClick={nextSlide} />
              <div className={styles.slide}>
                <img src={MascotData[currentSlide].image} alt="mascot image" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BrandAssets;
