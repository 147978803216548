import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ListObjectsCommand, S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';

import { show } from 'store/slices/alert';

const client = new S3Client({
  region: 'eu-central-1',
  credentials: fromCognitoIdentityPool({
    clientConfig: { region: 'eu-central-1' },
    identityPoolId: 'eu-central-1:2b8a4075-3053-4010-94b5-130c6ee172bd',
  }),
});

const getItems = async (name, prefixes) => {
  if (Array.isArray(prefixes)) {
    const results = await Promise.all(
      prefixes.map((prefix) =>
        client.send(new ListObjectsCommand({ Bucket: name, Prefix: prefix })),
      ),
    );

    return results.flatMap(({ Contents }) => Contents ?? []);
  }

  const { Contents } = await client.send(
    new ListObjectsCommand({ Bucket: name, Prefix: prefixes }),
  );

  return Contents ?? [];
};

export const useS3Bucket = ({
  name = 'evoplay-cloud',
  prefixes,
  formatItems,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const items = await getItems(name, prefixes);

      error && setError(false);

      return formatItems?.(items, prefixes) ?? items;
    } catch (e) {
      dispatch(show({ type: 'error', text: e.message }));
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [name, prefixes, error, formatItems]);

  return {
    fetchData,
    loading,
    error,
  };
};
