import React from 'react';

import useGetTournaments from 'hooks/tournaments/tournaments';

import { findPatchPages } from 'utils/pages';
import { getTournamentsByStatus } from 'utils/tournaments';

import ArrowCircleLink from 'components/ArrowCircleLink/ArrowCircleLink';
import Banner from 'components/pages/Tournaments/Banner/Banner';
import Card from 'components/pages/Tournaments/Card/Card';

import { PAGE_PATH } from 'components/config/pages';
import { STATUSES } from 'components/config/tournaments';

import styles from 'components/pages/Tournaments/Tournaments.module.scss';

const Tournaments = () => {
  const tournamentEvents = useGetTournaments();

  const running = tournamentEvents
    ? getTournamentsByStatus(tournamentEvents, STATUSES.running)
    : [];

  const comingSoon = tournamentEvents
    ? getTournamentsByStatus(tournamentEvents, STATUSES.comingSoon)
    : [];

  const ended = tournamentEvents
    ? getTournamentsByStatus(tournamentEvents, STATUSES.ended)
    : [];

  return (
    <div className={styles.wrapper}>
      {running.length > 0 && (
        <div className={styles.running}>
          <div className={styles.title}>
            <div>
              <span>Running</span>
            </div>
            {running.length > 1 && (
              <div>
                <ArrowCircleLink
                  path={findPatchPages(PAGE_PATH.TOURNAMENTS_CATEGORY).replace(
                    ':category',
                    'running',
                  )}
                  title="All Running"
                />
              </div>
            )}
          </div>
          <div className={styles.content}>
            <Banner item={running[0].event} />
          </div>
        </div>
      )}
      {comingSoon.length > 0 && (
        <div className={styles.comingSoon}>
          <div className={styles.title}>
            <div>
              <span>Coming soon</span>
            </div>
            {comingSoon.length > 1 && (
              <div>
                <ArrowCircleLink
                  path={findPatchPages(PAGE_PATH.TOURNAMENTS_CATEGORY).replace(
                    ':category',
                    'comingSoon',
                  )}
                  title="All Coming Soon"
                />
              </div>
            )}
          </div>
          <div className={styles.content}>
            <Banner item={comingSoon[0].event} />
          </div>
        </div>
      )}
      {ended.length > 0 && (
        <div className={styles.ended}>
          <div className={styles.title}>
            <div>
              <span>Ended</span>
            </div>
            <div>
              {ended.length > 4 && (
                <ArrowCircleLink
                  path={findPatchPages(PAGE_PATH.TOURNAMENTS_CATEGORY).replace(
                    ':category',
                    'ended',
                  )}
                  title="All Ended"
                />
              )}
            </div>
          </div>
          <div className={styles.content}>
            {ended.slice(0, 4).map((item, index) => (
              <Card key={index} item={item.event} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default Tournaments;
