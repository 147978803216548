import Img1 from 'images/NewsAndUpdates/Updates/712x479_1.jpg';
import Img2 from 'images/NewsAndUpdates/Updates/712x479_2.jpg';
import Img3 from 'images/NewsAndUpdates/Updates/712x479_3.jpg';
import Img4 from 'images/NewsAndUpdates/Updates/712x479_4.jpg';
import Img5 from 'images/NewsAndUpdates/Updates/712x479_5.jpg';
import Img6 from 'images/NewsAndUpdates/Updates/712x479_6.jpg';
import Banner1 from 'images/NewsAndUpdates/Updates/1500x420_1.jpg';
import Banner2 from 'images/NewsAndUpdates/Updates/1500x420_2.jpg';
import Banner3 from 'images/NewsAndUpdates/Updates/1500x420_3.jpg';
import Banner4 from 'images/NewsAndUpdates/Updates/1500x420_4.jpg';
import Banner5 from 'images/NewsAndUpdates/Updates/1500x420_5.jpg';
import Banner6 from 'images/NewsAndUpdates/Updates/1500x420_6.jpg';
import BonusBuy from 'images/NewsAndUpdates/Updates/BonusBuy.jpg';
import BonusBuyBg from 'images/NewsAndUpdates/Updates/BonusBuyBg.jpg';
import Quests from 'images/NewsAndUpdates/Updates/Quests.jpg';
import QuestsBg from 'images/NewsAndUpdates/Updates/QuestsBg.jpg';
import Removing from 'images/NewsAndUpdates/Updates/removing.jpg';
import RemovingBg from 'images/NewsAndUpdates/Updates/removingBg.jpg';

export const Updates = [
  {
    title: 'Removing ten games from our portfolio',
    img: Removing,
    background: RemovingBg,
    shortDescription:
      'We would like to inform you that in a short period of time, we are removing the following ten games from our portfolio',
    description:
      '<p><h3>We would like to inform you that in a short period of time, we are removing the following ten games from our portfolio:</h3></p>' +
      '<p>Legend of Kaan<br>Sprinkle<br>E.T. Races<br>Monster Lab<br>Christmas Party<br>Mehen<br>Lottery Ticket<br>Super Bartender<br>Funny Hunting<br>Magic Chests</p>' +
      '<p>The deactivation date is <strong>April 25</strong>.</p>' +
      "<p>While these games had their time in the spotlight, we're now making room for a new wave of high-quality games that will redefine iGaming." +
      '<p>We kindly request your assistance in removing the mentioned titles from your gaming lobby. Thank you for your partnership!</p>',
    date: '23.04.2024',
  },
  {
    title:
      'Introducing the Evoplay Quests: Elevating Retention with Gamification',
    img: Quests,
    background: QuestsBg,
    shortDescription:
      'Gamification serves as the cornerstone for fostering player loyalty in the iGaming industry.',
    description:
      "<p><strong>Gamification</strong> serves as the cornerstone for fostering player loyalty in the iGaming industry. Keeping this in mind, we're introducing the <strong>Evoplay Quests</strong>. This groundbreaking addition marks a significant stride in elevating <strong>engagement and retention</strong>.</p>" +
      '<p>Our quest tool seamlessly integrates gamified elements into the player experience, offering captivating missions that enhance gameplay and incentivise participation. Crafted to tap into the innate human desire for challenge and accomplishment, quests forge a deep connection between players and online casinos, nurturing <strong>long-lasting loyalty</strong>.</p>' +
      "<p>Take your casino's performance to new heights by embracing the power of gamification with the Evoplay Quests!<br/>Get in touch with your dedicated Key Account Manager today to discover how you can implement the tool and tailor it to suit your business needs for maximum efficiency.</p>",
    date: '05.04.2024',
  },
  {
    title: 'Bonus Buy integrated in every game by default!',
    img: BonusBuy,
    background: BonusBuyBg,
    shortDescription:
      'At Evoplay, we are continuously driven by analytics to enhance our solutions, relying on the power of numbers.',
    description:
      '<p><h3>At Evoplay, we are continuously driven by analytics to enhance our solutions, relying on the power of numbers. Our recent data indicate that our games featuring Bonus Buy outperform those without, boasting a 79% increase in Bet Sum and almost 100% higher GGR.</h3></p>' +
      '<p>In light of these promising results, we have made the strategic decision to integrate Bonus Buy as a default feature in all our upcoming game releases. This means that starting with the launch of Candy Craze, it will be automatically included, offering an elevated gaming experience right from the start.</p>' +
      '<p>Should you prefer to opt out of this functionality, our technical support team is available to assist you in the process. Alternatively, you may choose to embrace this feature and witness the positive impact it can have on your metrics.</p>' +
      "<p>We are confident these changes will significantly strengthen your casino business's performance!</p>",
    date: '05.04.2024',
  },
  {
    title: 'Revolutionising Tournaments: Autopayments Take the Stage',
    img: Img1,
    background: Banner1,
    shortDescription:
      "To enhance the efficiency of awarding prizes for tournaments, we're introducing Autopayments.",
    description:
      "<p><h3>To enhance the efficiency of awarding prizes for tournaments, we're introducing Autopayments. This useful tool aims to improve the way tournament rewards are granted to players by doing it automatically.</h3></p>" +
      '<p>Autopayments are an essential tool for our partners as they unlock access to our other engagement-boosting marketing activities that require it. This feature significantly simplifies and improves the execution process, thus streamlining the overall procedure.</p>' +
      '<p>For more information, you can refer to the "Callbacks" section in “SeamlessWallet API documentation”, specifically the description of the "BalanceIncrease" callback type, available on the Documentation page of your Client Area.</p>' +
      '<p>Feel free to test out the tool and see how it operates in the corresponding section of the "Callback Test" tab in the new BO.</p>' +
      "<p>We would greatly appreciate it if you could inform us once you've integrated the Autopayments functionality. This will allow us to utilise it for all upcoming network and local tournaments.</p>",
    date: '27.02.2023',
  },
  {
    title: 'Introducing Tournaments with Random Prize Drops',
    img: Img2,
    background: Banner2,
    shortDescription:
      "To elevate our partners' engagement and retention strategies, we're introducing a new addition to Evoplay's tournaments: Random Prize Drops.",
    description:
      "<p><h3>To elevate our partners' engagement and retention strategies, we're introducing a new addition to Evoplay's tournaments: Random Prize Drops. This tool is designed to inject an element of surprise and anticipatory excitement into your platform, thereby strengthening the bond between players and the casino.</h3></p>" +
      '<p>Throughout the day, a predetermined number of enticing prizes await your players, poised to be won at unpredictable intervals. As players observe the diminishing pool of prizes, their excitement intensifies. This immersive experience keeps them actively involved and eagerly anticipating their return, nurturing a profound sense of loyalty, amplifying customer lifetime value, and ensuring a deep-rooted connection with your casino brand.</p>' +
      '<p>For further details and to start implementing the tool, get in touch with your Evoplay Key Account Manager or reach out to us via email at <a href="mailto:business@evoplay.games">business@evoplay.games</a>.</p>',
    date: '15.09.2023',
  },
  {
    title: 'Enhanced Flexibility: Crash Games Now Allow Custom Bet Ranges',
    img: Img3,
    background: Banner3,
    shortDescription:
      "At Evoplay, we're committed to delivering innovative gaming experiences that cater to the unique preferences of our partners and players.",
    description:
      "<p><h3>At Evoplay, we're committed to delivering innovative gaming experiences that cater to the unique preferences of our partners and players. That's why we're excited to introduce an option that brings even more flexibility to our crash games. Our partners can customise the minimum and maximum bets in such titles, allowing them to align the game with specific markets.</h3></p>" +
      '<p>Previously, the minimum bet for crash games was set at $1, but now it has been lowered to 10 cents. This change opens up a world of opportunities for operators, making it easier than ever to tailor the game to various demographics.</p>' +
      "<p>If you're interested in adjusting the bet range in our crash games or have any questions about this option, reach out to the Evoplay technical support team.</p>",
    date: '14.06.2023',
  },
  {
    title:
      "Elevate Player Experiences with Free Rounds in Evoplay's Instant Games",
    img: Img4,
    background: Banner4,
    shortDescription:
      'In our relentless pursuit of enhancing player engagement and retention, we are introducing a new feature - Free Rounds',
    description:
      '<p><h3>In our relentless pursuit of enhancing player engagement and retention, we are introducing a new feature - <b>Free Rounds</b> in our lineup of instant games, including the fan-favourite football-themed hit, <a href="/games/penalty_shoot_out">Penalty Shoot-out</a>.</h3></p>' +
      '<p>Free Rounds, a concept akin to Free Spins in online slots, have proven to be a game-changer for both players and operators. Our partners have witnessed remarkable improvements in player metrics with the Bonus Buy feature, a beloved addition among online casino players, leading to growth in GGR of over <i>400%</i> compared to versions without it.</p>' +
      "<p>Building on this resounding success, we've taken the next step to elevate player experiences in our instant games by introducing the ability to purchase Free Rounds. This enhancement isn't limited to just one game; it extends across an extensive list of titles, making it accessible to players in a wide array of gaming scenarios:</p>" +
      '<div style="display: flex; column-gap: 40px">' +
      '<ul>' +
      '<li><a href="/games/penalty_shoot_out">Penalty Shoot-out</a></li>' +
      '<li><a href="/games/penalty_shoot_out_street">Penalty Shoot-out: Street</a></li>' +
      '<li><a href="/games/elven_princesses_crown_quest">Elven Princesses: Crown Quest</a></li>' +
      '<li><a href="/games/penalty_roulette">Penalty Roulette</a></li>' +
      '<li><a href="/games/soccer_solo_striker">Soccer Solo Striker</a></li>' +
      '<li><a href="/games/treasures_of_the_gods">Treasures of the Gods</a></li>' +
      '<li><a href="/games/super_bartender">Super Bartender</a></li>' +
      '<li><a href="/games/bingo">Candy Dreams: Bingo</a></li>' +
      '<li><a href="/games/funny_hunting">Funny Hunting</a></li>' +
      '<li><a href="/games/perfect_fishing">Perfect Fishing</a></li>' +
      '<li><a href="/games/lottery_ticket">Lottery Ticket</a></li>' +
      '<li><a href="/games/old_west">Old West</a></li>' +
      '</ul>' +
      '<ul>' +
      '<li><a href="/games/magic_chests">Magic Chests</a></li>' +
      '<li><a href="/games/pride_fight">Pride Fight</a></li>' +
      "<li><a href='/games/patricks_magic_field'>Patrick's Magic Field</a></li>" +
      '<li><a href="/games/penalty_series">Penalty Series</a></li>' +
      '<li><a href="/games/bonanza_wheel">Bonanza Wheel</a></li>' +
      '<li><a href="/games/plingo_ball">Plingoball</a></li>' +
      '<li><a href="/games/roll_to_luck">Roll to Luck</a></li>' +
      '<li><a href="/games/treasure_mania">Treasure Mania</a></li>' +
      '<li><a href="/games/xmas_keno_cat">Xmas Keno Cat</a></li>' +
      '<li><a href="/games/wheel_of_time">Wheel of Time</a></li>' +
      '<li><a href="/games/mine_field">Mine Field</a></li>' +
      '<li><a href="/games/scratch_match">Scratch Match</a></li>' +
      '</ul>' +
      '</div>' +
      '<p>With this new feature, players can take their gaming experience to the next level, adding an extra layer of excitement and engagement to their favourite instant games.</p>',
    date: '18.08.2023',
  },
  {
    title:
      "Level Up Your Casino Branding with Evoplay's Branded Games Solution",
    img: Img5,
    background: Banner5,
    shortDescription:
      'At Evoplay, we understand that establishing a strong brand presence is paramount.',
    description:
      '<p><h3>At Evoplay, we understand that establishing a strong brand presence is paramount. Having successfully built our own brand name, we are now excited to offer you a unique opportunity to elevate your casino brand to new heights and stand out in the eyes of your audience.</h3></p>' +
      "<p>Introducing our exclusive offer: the ability to embed your logo into our games. Place it in any front-end area or item of our titles and significantly increase your casino's brand visibility. This not only enhances your business's exclusivity but also cements your special place in the market.</p>" +
      "<p>Branded games versus their original counterparts have shown substantial growth, with an average difference of <b>Rounds</b> played increasing by <b>56%</b>, <b>Bet Sum</b> - by <b>74%</b>, <b>GGR</b> - by <b>72%</b>, and a staggering <b>190%</b> surge in the number of users. This isn't just about adding your logo; it's about enhancing your brand identity and boosting your revenue potential!</p>" +
      '<p>To learn more about our Branded Games offer and discover how it can transform your casino brand, we invite you to dive into our comprehensive <a href="https://cloud.playevoplay.com/s/CbEYGk7wAfXyWij#pdfviewer">presentation</a>.</p>' +
      "<p>Don't hesitate to reach out to your Evoplay Key Account Manager, who will guide you through the process of making your casino brand truly special and putting it in the spotlight via our products.</p>",
    date: '06.04.2023',
  },
  {
    title:
      "Boost Your Casino Activity via Evoplay's Tournaments with Bonus Buy Games",
    img: Img6,
    background: Banner6,
    shortDescription:
      "Our most recent development is poised to enhance the tournaments, as we're excited to announce the integration of our popular Bonus Buy games",
    description:
      "<p><h3>Our most recent development is poised to enhance the tournaments, as we're excited to announce the integration of our popular Bonus Buy games into these events, forming a winning combination for a casino business.</h3></p>" +
      "<p>Our tournament tool has shown remarkable statistics. On average, during tournaments, we've observed a substantial increase in a title’s key metrics, including <b>GGR - by 35%, Bet Sum - by 28%, Rounds - by 35%</b>, and a whopping <b>62% increase in Users</b>. Including mega-popular Bonus Buy games can boost the dynamics of these events even more!</p>" +
      "<p>Don't miss out on the chance to maximise the positive impact on your casino growth. Keep an eye on our upcoming tournaments, and be sure to participate to unlock the full potential of our offerings!</p>",
    date: '14.08.2023',
  },
];
