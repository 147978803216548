import React from 'react';
import { useParams } from 'react-router-dom';

import { useGetPostsQuery } from 'store/api/gameService/recipe.api';

import { findPatchPages } from 'utils/pages';

import ArrowCircleLink from 'components/ArrowCircleLink/ArrowCircleLink';
import Loader from 'components/Loader/Loader';
import List from 'components/pages/NewsAndUpdates/List/List';

import { News as NewsData, categories } from 'components/config/news';
import { PAGE_PATH } from 'components/config/pages';
import { Updates } from 'components/config/updates';

import stylesNews from 'components/pages/NewsAndUpdates/NewsAndUpdates.module.scss';

const LatestNews = () => {
  const { item, category } = useParams();
  const OldData = category === 'updates' ? Updates : NewsData;
  const { isLoading, data } = useGetPostsQuery({
    size: 100,
    post_type: categories[category].postType,
  });

  const items = data?.totalElements
    ? data?.content?.filter(
        ({ postType }) => postType === categories[category].postType,
      )
    : OldData;

  const latestNews = items
    ?.filter((el) => el.title !== item && el.id !== Number(item))
    ?.slice(0, 4);

  if (isLoading) {
    return <Loader />;
  }

  if (!latestNews?.length) {
    return null;
  }

  return (
    <div className={stylesNews.newsSection}>
      <div className={stylesNews.content}>
        <div className={stylesNews.header}>
          <div className={stylesNews.title}>Latest {category}</div>
          <ArrowCircleLink
            path={findPatchPages(PAGE_PATH.NEWS_CATEGORY).replace(
              ':category',
              category,
            )}
            title={`All ${
              category.charAt(0).toUpperCase() + category.slice(1)
            }`}
          />
        </div>
        <div className={stylesNews.list}>
          {latestNews.map((post, index) => (
            <List {...post} key={index} category={category} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LatestNews;
