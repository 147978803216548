import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';

import { useGetPostsQuery } from 'store/api/gameService/recipe.api';

import { findPatchPages } from 'utils/pages';

import ArrowCircleLink from 'components/ArrowCircleLink/ArrowCircleLink';
import Loader from 'components/Loader/Loader';
import List from 'components/pages/NewsAndUpdates/List/List';
import NotFound from 'components/pages/NotFound/NotFound';

import { News as OldNews, categories } from 'components/config/news';
import { PAGE_PATH } from 'components/config/pages';
import { Updates as OldUpdates } from 'components/config/updates';

import styles from 'components/pages/NewsAndUpdates/Category/Category.module.scss';
import baseStyles from 'components/pages/NewsAndUpdates/NewsAndUpdates.module.scss';

export const Category = () => {
  const { category } = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [items, setItems] = useState([]);
  const [ref, inView] = useInView({});

  const countElements = 16;

  const { isLoading, data } = useGetPostsQuery({
    size: countElements,
    page: currentPage,
  });

  useEffect(() => {
    if (inView && !isLoading) {
      setCurrentPage((prevState) => prevState + 1);
    }
  }, [inView]);

  useEffect(() => {
    if (!isLoading) {
      let results = [];
      if (data?.totalElements) {
        results = data?.content?.filter(
          ({ postType }) =>
            postType === (category === 'news' ? 'NEWS' : 'UPDATE'),
        );
      } else {
        results = (category === 'news' ? OldNews : OldUpdates).slice(
          currentPage * countElements,
          (currentPage + 1) * countElements,
        );
      }
      if (results) {
        setItems((prevItems) => [...prevItems, ...results]);
      }
    }
  }, [data, isLoading]);

  if (currentPage === 0 && isLoading) {
    return <Loader />;
  }

  if (!categories[category] || !items) {
    return <NotFound />;
  }

  return (
    <div className={baseStyles.content}>
      <div className={styles.back}>
        <ArrowCircleLink
          path={findPatchPages(PAGE_PATH.NEWS)}
          title="Back"
          direction="left"
        />
      </div>
      <div className={baseStyles.header}>
        <div className={baseStyles.title}>{categories[category].title}</div>
      </div>
      <div className={baseStyles.list}>
        {items.map((item, index) => (
          <List {...item} key={index} category={category} />
        ))}
      </div>
      <div ref={ref}>{isLoading && <Loader />}</div>
    </div>
  );
};

export default Category;
