import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { viewModeSelector } from 'store/slices/viewMode';

import { findPatchPages } from 'utils/pages';
import { getComingSoon, getEnded, getRunning } from 'utils/promotions';
import { VIEW_MODE } from 'utils/viewMode';

import ArrowCircleLink from 'components/ArrowCircleLink/ArrowCircleLink';
import Card from 'components/pages/Promotions/Card/Card';

import { PAGE_PATH } from 'components/config/pages';
import { data } from 'components/config/promotions';

import { ReactComponent as PromoIcon } from 'images/Icons/Promo.svg';

import styles from 'components/pages/Promotions/Promotions.module.scss';

const Promotions = () => {
  const { provider } = useParams();
  const viewMode = useSelector(viewModeSelector.getViewMode);
  const countSize = {
    comingSoon: viewMode === VIEW_MODE.SIMPLE ? 3 : 4,
    running: viewMode === VIEW_MODE.SIMPLE ? 2 : 3,
    ended: viewMode === VIEW_MODE.SIMPLE ? 4 : 6,
  };

  const comingSoon = getComingSoon(data[provider]);
  const running = getRunning(data[provider]);
  const ended = getEnded(data[provider]);

  return (
    <div className={styles.wrapper}>
      {comingSoon.length > 0 && (
        <div className={styles.comingSoon}>
          <div className={styles.title}>
            <div>
              <span>Coming soon</span>
              <PromoIcon />
              <span>{comingSoon.length}</span>
            </div>
            <div>
              {comingSoon.length > countSize.comingSoon && (
                <ArrowCircleLink
                  path={findPatchPages(PAGE_PATH.PROMOTIONS_CATEGORY)
                    .replace(':provider', provider)
                    .replace(':category', 'comingSoon')}
                  title="All Coming Soon"
                />
              )}
            </div>
          </div>
          <div className={styles.content}>
            {comingSoon.slice(0, countSize.comingSoon).map((item, index) => (
              <Card
                key={`comingSoon${index}`}
                item={item}
                provider={provider}
                category="comingSoon"
              />
            ))}
          </div>
        </div>
      )}
      {running.length > 0 && (
        <div className={styles.running}>
          <div className={styles.title}>
            <div>
              <span>Running</span>
              <PromoIcon />
              <span>{running.length}</span>
            </div>
            <div>
              {running.length > countSize.running && (
                <ArrowCircleLink
                  path={findPatchPages(PAGE_PATH.PROMOTIONS_CATEGORY)
                    .replace(':provider', provider)
                    .replace(':category', 'running')}
                  title="All Running"
                />
              )}
            </div>
          </div>
          <div className={styles.content}>
            {running.slice(0, countSize.running).map((item, index) => (
              <Card
                key={`running${index}`}
                item={item}
                category="running"
                provider={provider}
              />
            ))}
          </div>
        </div>
      )}
      {ended.length > 0 && (
        <div className={styles.ended}>
          <div className={styles.title}>
            <div>
              <span>Ended</span>
              <PromoIcon />
              <span>{ended.length}</span>
            </div>
            <div>
              {ended.length > countSize.ended && (
                <ArrowCircleLink
                  path={findPatchPages(PAGE_PATH.PROMOTIONS_CATEGORY)
                    .replace(':provider', provider)
                    .replace(':category', 'ended')}
                  title="All Ended"
                />
              )}
            </div>
          </div>
          <div className={styles.content}>
            {ended.slice(0, countSize.ended).map((item, index) => (
              <Card
                key={`ended${index}`}
                item={item}
                category="ended"
                provider={provider}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default Promotions;
